import React from "react";
import { useState, useEffect } from "react";
import "./SortItem.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FaUndo } from "react-icons/fa";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "3px",
  borderBottom: "2px solid #F26654",
};

const optionStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.5rem",
};

const groupLabelStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#F26654",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const optionLabelStyles = {
  overflowWrap: "anywhere",
};

const SortItem = ({
  products,
  setBrandFilter,
  setColorFilter,
  setSizeFilter,
  brandFilter,
  colorFilter,
  sizeFilter,
  setCategoryFilter,
  categoryFilter,
  location,
  setLocation,
  distance,
  setDistance,
  setProductSearch,
  productSearch,
  handleSearch,
  setConditionFilter,
  conditionFilter,
  setClosetFilter,
  closetFilter,
  setExclusiveFilter,
  exclusiveFilter,
  hangerFilter,
  setHangerFilter,
  filters,
  setPageNumber,
  setSearchFilter,
  setSortFilter,
  setSwitchTrigger,
  toast,
  toastOption,
  setResetFilt,
  setPincode,
  pinCode,
  setNonMemberChecked,
  nonMemberChecked,
  setMemberChecked,
  memberChecked,
  setRefresh,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
    }),
  };

  const [brand, setBrand] = useState([]);
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);
  const [category, setCategory] = useState([]);
  const [condition, setCondition] = useState([]);
  const [closet, setCloset] = useState([]);
  const [hanger, setHanger] = useState([]);
  const [membersItemsChecked, setMembersItemChecked] = useState(false);
  const [nonMembersItemsChecked, setNonMembersItemChecked] = useState(false);

  useEffect(() => {
    //console.log(filters);
    if (filters) {
      let tmpbrnd =
        filters.brand && filters.brand.length > 0 ? [...filters.brand] : [];
      let tmpColor =
        filters.color && filters.color.length > 0 ? [...filters.color] : [];
      let tmpCatg =
        filters.category && filters.category.length > 0
          ? [...filters.category]
          : [];
      let tmpCond =
        filters.condition && filters.condition.length > 0
          ? [...filters.condition]
          : [];
      let tmpHangr =
        filters.hanger && filters.hanger.length > 0 ? [...filters.hanger] : [];
      let brd = [];
      let sz = [];
      let clr = [];
      let catg = [];
      let cond = [];
      let clst = [];
      let hngr = [];
      let women = [
        {
          label: "Women",
          value: "Women",
          pt: ["Women"],
        },
      ];
      let men = [
        {
          label: "Men",
          value: "Men",
          pt: ["Men"],
        },
      ];
      let boys = [
        {
          label: "Boy",
          value: "Boy",
          pt: ["Boy"],
        },
      ];
      let girls = [
        {
          label: "Girl",
          value: "Girl",
          pt: ["Girl"],
        },
      ];
      let others = [];
      filters.brand &&
        tmpbrnd.sort().map((val, index) => {
          brd.push({
            label: val,
            value: val,
          });
        });
      filters.size &&
        filters.size.map((val, index) => {
          sz.push({
            label: val,
            value: val,
          });
        });
      filters.color &&
        tmpColor.sort().map((val, index) => {
          if (!clr.some((clor) => clor.label === val.toLocaleLowerCase())) {
            clr.push({
              label: val.toLocaleLowerCase(),
              value: val.toLocaleLowerCase(),
            });
          }
        });
      filters.category &&
        tmpCatg.sort().map((val, index) => {
          let cat;
          val.map((ct, index) => {
            if (
              ct === "Men" ||
              ct === "Women" ||
              ct === "Girl" ||
              ct === "Boy" ||
              ct === "Other"
            ) {
              cat = ct;
              return null;
            }
            if (cat === "Women") {
              if (!women.some((wm) => wm.value === ct)) {
                women.push({
                  label: ct,
                  value: ct,
                  pt: ["Women"],
                });
                women.sort((a, b) => a.label.localeCompare(b.label));
              }
            } else if (cat === "Men") {
              if (!men.some((wm) => wm.value === ct)) {
                men.push({
                  label: ct,
                  value: ct,
                  pt: ["Men"],
                });
                men.sort((a, b) => a.label.localeCompare(b.label));
              }
            } else if (cat === "Girl") {
              if (!girls.some((wm) => wm.value === ct)) {
                girls.push({
                  label: ct,
                  value: ct,
                  pt: ["Girl"],
                });
                girls.sort((a, b) => a.label.localeCompare(b.label));
              }
            } else if (cat === "Boy") {
              if (!boys.some((wm) => wm.value === ct)) {
                boys.push({
                  label: ct,
                  value: ct,
                  pt: ["Boy"],
                });
                boys.sort((a, b) => a.label.localeCompare(b.label));
              }
            } else if (cat === "Other") {
              if (!others.some((wm) => wm.value === ct)) {
                others.push({
                  label: ct,
                  value: ct,
                  pt: ["Other"],
                });
                others.sort((a, b) => a.label.localeCompare(b.label));
              }
            }
          });
        });
      filters.condition &&
        tmpCond.sort().map((val, index) => {
          cond.push({
            label: val,
            value: val,
          });
        });
      filters.closet &&
        filters.closet.map((val, index) => {
          clst.push({
            label: val._id,
            value: val._id,
            count: val.count,
          });
        });
      filters.hanger &&
        tmpHangr.sort().map((val, index) => {
          hngr.push({
            label: val,
            value: val,
          });
        });

      catg = [
        {
          label: "Women",
          options: women,
        },
        {
          label: "Men",
          options: men,
        },
        {
          label: "Girls",
          options: girls,
        },
        {
          label: "Boys",
          options: boys,
        },
        {
          label: "Other",
          options: others,
        },
      ];
      setBrand(brd);
      setSize(sz);
      setColor(clr);
      setCategory(catg);
      setCondition(cond);
      setCloset(clst);
      setHanger(hngr);
    }
    // if (products.length > 0) {
    //   let brd = [];
    //   let sz = [];
    //   let clr = [];
    //   let catg = [];
    //   let cond = [];
    //   let clst = [];
    //   let hngr = [];
    //   products.map((val, index) => {
    //     if (
    //       !brd.some((e) => e.label === val.productStats.brand.toLowerCase())
    //     ) {
    //       brd.push({
    //         label: val.productStats.brand.toLowerCase(),
    //         value: val.productStats.brand.toLowerCase(),
    //       });
    //     }
    //     val.productStats.color.map((clor) => {
    //       if (!clr.some((e) => e.label === clor)) {
    //         clr.push({ label: clor, value: clor });
    //       }
    //     });
    //     val.productStats.category.map((ctg) => {
    //       if (!catg.some((e) => e.label === ctg)) {
    //         catg.push({ label: ctg, value: ctg });
    //       }
    //     });

    //     if (!sz.some((e) => e.label === val.productStats.size.toLowerCase())) {
    //       sz.push({
    //         label: val.productStats.size.toLowerCase(),
    //         value: val.productStats.size.toLowerCase(),
    //       });
    //     }
    //     if (
    //       !cond.some(
    //         (e) => e.label === val.productStats.condition.toLowerCase()
    //       )
    //     ) {
    //       cond.push({
    //         label: val.productStats.condition.toLowerCase(),
    //         value: val.productStats.condition.toLowerCase(),
    //       });
    //     }
    //     if (
    //       val.closetName &&
    //       !clst.some((e) => e.label === val.closetName.toLowerCase())
    //     ) {
    //       clst.push({
    //         label: val.closetName.toLowerCase(),
    //         value: val.closetName.toLowerCase(),
    //       });
    //     }
    //     if (
    //       val.productHanger &&
    //       !hngr.some((e) => e.label === val.productHanger)
    //     ) {
    //       hngr.push({ label: val.productHanger, value: val.productHanger });
    //     }
    //   });
    //   setBrand(brd);
    //   setSize(sz);
    //   setColor(clr);
    //   setCategory(catg);
    //   setCondition(cond);
    // setCloset(clst);
    //   setHanger(hngr);
    // }
  }, [products]);

  const exlusiveOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  return (
    <div className="admin-menu-container">
      <div
        className="h3 text-uppercase text-center bold-text"
        style={{
          margin: "auto",
          borderBottom: "1px solid var(--background-color)",
          color: "var(--background-color)",
          width: "100%",
          textAlign: "center",
        }}
      >
        Search Products
      </div>
      <br />
      <div className="input_container">
        <form
          style={{ display: "flex" }}
          onSubmit={(e) => {
            setRefresh(true);
            handleSearch(e);
            setSwitchTrigger(true);
          }}
        >
          <input
            type="text"
            id="productName"
            name="productName"
            onChange={(e) => setProductSearch(e.target.value)}
            value={productSearch}
            placeholder="Enter Product Name"
            style={{
              marginTop: "0px !important",
              fontFamily: "inherit",
              borderRadius: "3px 0 0 3px",
            }}
          />
          <button
            type="submit"
            className="btn"
            style={{ borderRadius: "0 3px 3px 0" }}
            // onClick={(e) => {
            //   handleSearch(e);
            //   setSwitchTrigger(true);
            // }}
          >
            <i class="fas fa-search fa-lg"></i>
          </button>
        </form>
      </div>
      <br />
      <br />
      <div
        className="h3 text-uppercase bold-text"
        style={{
          margin: "auto",
          borderBottom: "1px solid var(--background-color)",
          color: "var(--background-color)",
          width: "100%",
        }}
      >
        Filter
        {(location !== "" ||
          distance !== "" ||
          hangerFilter.length > 0 ||
          categoryFilter.length > 0 ||
          brandFilter.length > 0 ||
          sizeFilter.length > 0 ||
          colorFilter.length > 0 ||
          conditionFilter.length > 0 ||
          closetFilter.length > 0 ||
          productSearch.length > 0) && (
          <span
            className="small-text"
            style={{
              marginRight: 0,
              float: "right",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setRefresh(true);
              setPincode("");
              setLocation("");
              setDistance("");
              setSortFilter("");
              setHangerFilter([]);
              setCategoryFilter([]);
              setBrandFilter([]);
              setSizeFilter([]);
              setColorFilter([]);
              setConditionFilter([]);
              setClosetFilter([]);
              setExclusiveFilter({ label: "All", value: "All", first: false });
              setProductSearch("");
              setSearchFilter("");
              setSwitchTrigger(true);
              setResetFilt((prev) => !prev);
              sessionStorage.removeItem("closetshopzipfilter");
            }}
          >
            Reset <FaUndo style={{ fontSize: "11px" }} />
          </span>
        )}
      </div>
      <br />
      <div className="h4">Shop By Location</div>
      <div className="input_container">
        <input
          type="text"
          id="itemName"
          name="itemName"
          onChange={(e) => {
            setLocation(Number(e.target.value));
            setPincode(e.target.value);
            setDistance("");
            sessionStorage.setItem(
              "closetshopzipfilter",
              JSON.stringify({
                location: e.target.value,
                distance: "",
              })
            );
          }}
          value={pinCode}
          placeholder="Enter ZipCode"
          style={{ fontFamily: "inherit" }}
        />
      </div>
      {/* <div className="menu_container">
        <div><Link to={`/add-items`} style={{textDecoration:"none"}}> <i class="fa-solid fa-location-dot"></i> &nbsp; Get My Location</Link></div>
      </div> */}
      <div className="input_container">
        {/* <select
          id="category"
          name="category"
          onChange={(event) => setDistance(event.target.value)}
          value={distance}
        >
          <option value="" selected>
            Choose the Distance
          </option>

          <>
            <option value={10}>10 miles</option>
            <option value={50}>50 miles</option>
            <option value={100}>100 miles</option>
            <option value={500}>500 miles</option>
            <option value={1000}>1000 miles</option>
            <option value={5000}>All</option>
          </>
        </select> */}
        <Select
          options={[
            { label: "10 Miles", value: 10 },
            { label: "50 Miles", value: 50 },
            { label: "100 Miles", value: 100 },
            { label: "500 Miles", value: 500 },
            { label: "1000 Miles", value: 1000 },
            { label: "5000 Miles", value: 5000 },
          ]}
          onChange={(value) => {
            if (location > 0 && location.toString().length == 5) {
              setRefresh(true);
              setDistance(value);
            } else {
              toast.error("Invalid Zip Code", toastOption);
            }
          }}
          value={distance}
          styles={customStyles}
          placeholder="Choose the Distance"
        ></Select>
        {/* <button
          className="btn bold-text"
          onClick={() => setLocation(Number(pinCode))}
          style={{
            width: "100%",
            textTransform: "uppercase",
          }}
        >
          Filter by Location
        </button> */}
      </div>
      <br />
      <br />
      {/* <button
        className="btn text-uppercase bold-text small-text"
        style={{ width: "100%" }}
        disabled={products.length === 0 ? true : false}
        onClick={() => {
          setExclusiveFilter((prevState) => {
            return prevState.label === "Yes"
              ? { label: "No", value: "false", first: false }
              : { label: "Yes", value: "true", first: false };
          });
          setSwitchTrigger(true);
        }}
      >
        {" "}
        {exclusiveFilter.label === "Yes"
          ? "Show All"
          : "Filter by Members Only Items"}
      </button> */}
      <div className="input_container">
        <div
          style={{ margin: "0 10px", display: "flex", alignItems: "center" }}
        >
          <input
            type={"checkbox"}
            defaultChecked={membersItemsChecked}
            id="members_only"
            style={{ transform: "scale(1.3)", padding: 0, margin: 0 }}
            checked={memberChecked}
            onChange={(e) => {
              //console.log(e.target.checked);
              setMembersItemChecked((prev) => !prev);
              setExclusiveFilter((prevState) => {
                setMemberChecked(e.target.checked);
                return prevState.label === "NoNM"
                  ? { label: "All", value: "All", first: false, saved: false }
                  : prevState.label === "All" && !e.target.checked
                  ? { label: "NoNM", value: "NoNM", first: false, saved: false }
                  : {
                      label: e.target.checked ? "MeM" : "All",
                      value: e.target.checked ? "MeM" : "All",
                      first: false,
                      saved: false,
                    };
              });
            }}
          />{" "}
          <label
            className="h6"
            htmlFor="members_only"
            style={{ margin: "0 10px" }}
          >
            Show Member-Only Items
          </label>
        </div>
        <br />
        <div
          style={{ margin: "0 10px", display: "flex", alignItems: "center" }}
        >
          <input
            type={"checkbox"}
            defaultChecked={nonMembersItemsChecked}
            id="non_members_only"
            style={{ transform: "scale(1.3)", padding: 0, margin: 0 }}
            checked={nonMemberChecked}
            onChange={(e) => {
              setNonMembersItemChecked((prev) => !prev);
              setExclusiveFilter((prevState) => {
                //console.log("SDSD");
                setNonMemberChecked(e.target.checked);
                return prevState.label === "MeM"
                  ? { label: "All", value: "All", first: false, saved: false }
                  : prevState.label === "All" && !e.target.checked
                  ? { label: "MeM", value: "MeM", first: false, saved: false }
                  : {
                      label: e.target.checked ? "NoNM" : "All",
                      value: e.target.checked ? "NoNM" : "All",
                      first: false,
                      saved: false,
                    };
              });
            }}
          />{" "}
          <label
            className="h6"
            htmlFor="non_members_only"
            style={{ margin: "0 10px" }}
          >
            Show Non-Member Items
          </label>
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Hanger Credits</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            options={hanger}
            isMulti
            onChange={(value) => {
              setRefresh(true);
              setHangerFilter(value);
              setSwitchTrigger(true);
            }}
            value={hangerFilter}
            styles={customStyles}
          />
          {/* <select id="" name="" onChange={(event)=>setCategoryFilter(event.target.value)} value={categoryFilter}>
            <option value="" selected>Choose your category here</option>
            {
              category.length>0 && category.map((val,index)=>{
                return(
                  <option value={val}>{val}</option>
                )
              })
            }
          </select> */}
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Category</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            // options={category.sort((a, b) => a.label.localeCompare(b.label))}
            options={category}
            isMulti
            onChange={(value) => {
              setRefresh(true);
              setCategoryFilter(value);
              setSwitchTrigger(true);
            }}
            value={categoryFilter}
            styles={customStyles}
            formatGroupLabel={formatGroupLabel}
          />
          {/* <select id="" name="" onChange={(event)=>setCategoryFilter(event.target.value)} value={categoryFilter}>
            <option value="" selected>Choose your category here</option>
            {
              category.length>0 && category.map((val,index)=>{
                return(
                  <option value={val}>{val}</option>
                )
              })
            }
          </select> */}
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Brand</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            options={brand}
            isMulti
            onChange={(value) => {
              setRefresh(true);
              setBrandFilter(value);
              setSwitchTrigger(true);
            }}
            value={brandFilter}
            styles={customStyles}
          />
          {/* <select id="category" name="category" onChange={(event)=>setBrandFilter(event.target.value)} value={brandFilter}>
            <option value="" selected>Choose the brand here</option>
            {
              brand.length>0 && brand.map((val,index)=>{
                return(
                  <option value={val}>{val}</option>
                )
              })
            }
          </select> */}
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Size</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            options={size}
            isMulti
            onChange={(value) => {
              setRefresh(true);
              setSwitchTrigger(true);
              setSizeFilter(value);
            }}
            value={sizeFilter}
            styles={customStyles}
          />
          {/* <select id="category" name="category" onChange={(event)=>setSizeFilter(event.target.value)} value={sizeFilter}>
            <option value="" selected>Choose your size here</option>
            {
              size.length>0 && size.map((val,index)=>{
                return(
                  <option value={val}>{val}</option>
                )
              })
            }
          </select> */}
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Color</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            options={color.sort((a, b) => a.label.localeCompare(b.label))}
            isMulti
            onChange={(value) => {
              setRefresh(true);
              setColorFilter(value);
              setSwitchTrigger(true);
            }}
            value={colorFilter}
            styles={customStyles}
          />
          {/* <select id="category" name="category" onChange={(event)=>setColorFilter(event.target.value)} value={colorFilter}>
            <option value="" selected>Choose the color here</option>
            {
              color.length>0 && color.map((val,index)=>{
                return(
                  <option value={val}>{val}</option>
                )
              })
            }
          </select> */}
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Condition</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            options={condition.sort((a, b) => a.label.localeCompare(b.label))}
            isMulti
            onChange={(value) => {
              setRefresh(true);
              setConditionFilter(value);
              setSwitchTrigger(true);
            }}
            value={conditionFilter}
            styles={customStyles}
          />
          {/* <select id="category" name="category" onChange={(event)=>setConditionFilter(event.target.value)} value={conditionFilter}>

            <option value="" selected>Choose the condition here</option>
            {
              condition.length>0 && condition.map((val,index)=>{
                return(
                  <option value={val}>{val}</option>
                )
              }
              )
            }
          </select> */}
        </div>
      </div>
      <br />
      <br />
      <div className="h4">Shop By Closet</div>
      <div className="personal_info_container" style={{ width: "100%" }}>
        <div className="input_container">
          <Select
            options={closet}
            isMulti
            onChange={(value) => {
              setClosetFilter(value);
              setSwitchTrigger(true);
              setRefresh(true);
            }}
            value={closetFilter}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
          />
          {/* <select id="category" name="category" onChange={(event)=>setConditionFilter(event.target.value)} value={conditionFilter}>

              <option value="" selected>Choose the condition here</option>
              {
                condition.length>0 && condition.map((val,index)=>{
                  return(
                    <option value={val}>{val}</option>
                  )
                }
                )
              }
            </select> */}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={groupLabelStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const formatOptionLabel = (data) => (
  <div style={optionStyles}>
    <span style={optionLabelStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.count}</span>
  </div>
);

export default SortItem;
