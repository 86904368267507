import React, { useEffect, useState } from "react";
import "./Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import userImg from "../assets/images/user.png";

import { getuserInfo, reset } from "../components/StateSlices/getuserSlice";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import ProfileDescription from "../components/ProfileDescription/ProfileDescription";
import { FaTags, FaUpload, FaUser } from "react-icons/fa";
import { TiUpload } from "react-icons/ti";
import { MdTag } from "react-icons/md";
import { IoPricetag, IoPricetags } from "react-icons/io5";
import { PiCoatHangerFill } from "react-icons/pi";

const Profile = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const [spinner, setSpinner] = useState(false);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      setSpinner(true);
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 480);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  let lastDeletedId;

  useEffect(() => {
    if (profile) {
      console.log(profile);
      setSpinner(false);
      if (profile !== "complete") {
        navigate("/setup-closest");
      }
      if (!userInfo.userDetailsId.verified) {
        navigate("/confirmation?error=AccountNotVerified", { state: userInfo });
      }
    }
  }, [profile]);

  return (
    <div className="page-container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        /> */}
      {userInfo && (
        <div className="profile_container">
          {/* <h1 className="section_title bold-text">Your Account</h1> */}
          {/* <div className="profile_comp_container">
          <div className="profile_img_container">
            <img
              src={
                userInfo && userInfo.userDetailsId.userIcon
                  ? userInfo.userDetailsId.userIcon
                  : userImg
              }
              alt="profile icon"
            />
          </div>
          <div className="profile_right">
            <ProfileDescription userInfo={userInfo} />
          </div>
          <Link to="/user/edit-profile" className="btn dark-btn" type="button">
            Edit Profile
          </Link>
        </div> */}

          <div className="acc-quick-card">
            <div className="acc-quick-info">
              <div className="stats">
                <div className="stats">
                  <div className="icon">
                    <TiUpload size={"4em"} color="var(--background-color)" />
                  </div>{" "}
                  <div>
                    <h2 className="bold-text">
                      {userInfo.totalProduct.length}
                    </h2>
                    <h4>Items Posted</h4>
                  </div>
                </div>
              </div>
              <div className="ver-divider">&nbsp;</div>
              <div className="stats">
                <div className="icon">
                  <IoPricetags size={"3.5em"} color="var(--background-color)" />
                </div>{" "}
                <div>
                  <h2 className="bold-text">
                    {userInfo.userProductsId.purchaseId.length}
                  </h2>
                  <h4>Items Purchased</h4>
                </div>
              </div>
              <div className="ver-divider">&nbsp;</div>
              <div className="stats">
                <div className="icon">
                  <PiCoatHangerFill
                    size={"4em"}
                    color="var(--background-color)"
                  />
                </div>{" "}
                <div>
                  <h2 className="bold-text">{userInfo.userHanger}</h2>
                  <h4>Hanger Credits</h4>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "stretch",
                justifyContent: "space-evenly",
                margin: "2rem 0",
                marginBottom: "1rem",
              }}
            >
              <div className="personal-info">
                <h3
                  className="h3 bold-text background-text text-center"
                  style={{ marginBottom: "2rem" }}
                >
                  Personal Information
                </h3>
                <div className="personal-info-stat h5">
                  <b>Name</b>:{" "}
                  {userInfo.userDetailsId.userFName +
                    " " +
                    userInfo.userDetailsId.userLName}
                </div>
                <div className="personal-info-stat h5">
                  <b>Email</b>: {userInfo.userDetailsId.userEmail}
                </div>
                <div className="personal-info-stat h5">
                  <b>Gender</b>: {userInfo.userDetailsId.userGender}
                </div>
                <div className="personal-info-stat h5">
                  <b>Phone</b>: {userInfo.userDetailsId.userPhone}
                </div>
                <div className="personal-info-stat h5">
                  <b>Address</b>: {userInfo.userDetailsId.userAddress}
                </div>
                <div className="personal-info-stat h5">
                  <b>City</b>: {userInfo.userDetailsId.userCity}
                </div>
                <div className="personal-info-stat h5">
                  <b>State</b>: {userInfo.userDetailsId.userState}
                </div>
                <div className="personal-info-stat h5">
                  <b>Membership Type</b>: {userInfo.userDetailsId.membership}
                </div>
              </div>
              <div className="closet-info">
                <h3
                  className="h3 bold-text background-text text-center"
                  style={{ marginBottom: "2rem" }}
                >
                  Closet Information
                </h3>
                <div className="personal-info-stat">
                  <b>Closet Name</b>: {userInfo.userDetailsId.closetName}
                </div>
                <div className="personal-info-stat">
                  <b>Description</b>: {userInfo.userDetailsId.userDesc}
                </div>
                <div className="personal-info-stat">
                  <b>Closet Banner</b>:
                  <br />
                  {userInfo.userDetailsId.userBanner && (
                    <img
                      src={userInfo.userDetailsId.userBanner}
                      alt="closet Banner"
                      width={"100%"}
                      height={"200px"}
                      style={{ objectFit: "cover" }}
                    />
                  )}
                </div>
                <div className="personal-info-stat">
                  <b>Shirt Size</b>: {userInfo.userDetailsId.userShirtSize}
                </div>
                <div className="personal-info-stat">
                  <b>Shoe Size</b>: {userInfo.userDetailsId.userShoeSize}
                </div>
                <div className="personal-info-stat">
                  <b>Dress Size</b>: {userInfo.userDetailsId.userDressSize}
                </div>
              </div>
            </div>

            <div
              className="edit-profile-btn"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to={"/user/edit-profile"}>
                <button className="btn">Edit Profile</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
