import React, { useState, useEffect, useRef } from "react";
import { useFormik, Formik } from "formik";
import Founder from "../components/Founder/Founder";
import MembershipSelect from "../components/MembershipSelect/MembershipSelect";
import LoginForm from "../components/LoginForm/LoginForm";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, reset } from "../components/StateSlices/loginSlice";
import { reset as userInfoRest } from "../components/StateSlices/getuserSlice";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import {
  addtoCart,
  resetCartMessage,
} from "../components/StateSlices/addtocartSlice";

import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const Login = () => {
  const [membership, setMembership] = useState("free");
  const [tc, setTc] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [provider, setProvider] = useState("email");
  const [pEmail, setPEmail] = useState("");
  const formRef = useRef();

  useEffect(() => {
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = window.location.href;

    document.head.appendChild(canonicalLink);
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userLogedIn, error, userFullInfo } = useSelector(
    (state) => state.login
  );
  const { message } = useSelector((state) => state.cart);
  let toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      console.log(values);
      let payload = {};
      if (provider === "email") {
        payload = { ...values, provider };
      } else {
        payload = { email: pEmail, provider };
      }

      setSpinner(true);
      dispatch(loginUser(payload));
    },
  });

  if (userLogedIn) {
    let sessionCart = JSON.parse(sessionStorage.getItem("closetcart"));
    if (sessionCart) {
      dispatch(
        addtoCart({
          id: sessionCart,
          token: localStorage.getItem("clttoken"),
          arr: true,
        })
      );
    } else {
      dispatch(
        addtoCart({
          id: [],
          token: localStorage.getItem("clttoken"),
          arr: true,
        })
      );
      sessionStorage.setItem("closetcart", JSON.stringify([]));
    }
    dispatch(reset());
  }

  if (message) {
    toast.success("User Succesfully LoggedIn", toastOption);
    setProvider("email");
    setPEmail("");
    dispatch(resetCartMessage());

    navigate("/user/my-closet");
    // setTimeout(() => {

    // }, 5000);
  }

  if (error) {
    console.log(error);
    if (error.indexOf("Banned") > -1 || error.indexOf("Paused") > -1) {
      toastOption = {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      // let z = document.createElement('div')
      // z.innerHTML = error
      toast.error(
        ({ closeToast }) => <div dangerouslySetInnerHTML={{ __html: error }} />,
        toastOption
      );
    } else {
      toast.error(
        error.slice(
          0,
          error.indexOf("?") === -1 ? error.length : error.indexOf("?")
        ),
        toastOption
      );
    }

    setProvider("email");
    setPEmail("");
    dispatch(reset());
    setSpinner(false);
    if (error.includes("Password Reset Required")) {
      setTimeout(() => {
        navigate(`/resetpassword/${error.slice(error.indexOf("=") + 1)}`);
      }, 2000);
    }
  }

  // useEffect(() => {
  //   return () => {
  //     formik.setFieldValue(
  //       'membership',membership
  //     );

  //     setTimeout(() => formik.setFieldTouched(membership, true));
  //   }
  // }, [membership])

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Log in to access your account and explore exclusive features. Join our community and enjoy personalized services tailored to your needs."
        />
      </Helmet>
      <div
        className="page-container"
        style={{ position: "relative", fontFamily: "LATO" }}
      >
        {spinner ? <Spinner /> : ""}
        <div className="signup">
          {/* <ToastContainer
          position="bottom-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
          <Founder />
          <div className="signup_right">
            {/* <MembershipSelect membership={membership} formik={formik} setMembership={setMembership}/> */}
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values, actions) => {
                console.log(values);
                dispatch(userInfoRest());
                let payload = {};
                if (provider === "email") {
                  payload = { ...values, provider };
                } else {
                  payload = { email: pEmail, provider };
                }

                setSpinner(true);
                dispatch(loginUser(payload));
              }}
              innerRef={formRef}
              render={(props) => (
                <LoginForm
                  setTc={setTc}
                  status={status}
                  setProvider={setProvider}
                  {...props}
                  setPEmail={setPEmail}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
