import React from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import "./Pagination.css";

const Pagination = ({
  pageCount,
  pageNumber,
  setPageNumber,
  thresholdActive = true,
}) => {
  
  const navigate = useNavigate();
  const location = useLocation();
  //consolele.log(location.search);
  const handlePageClick = (data) => {
    // console.log("thre",thresholdActive);
    if (thresholdActive) {
      //consolele.log("NEXT PAGE 2");
      setPageNumber(data.selected);
      // console.log(data);
      sessionStorage.setItem("closetshoppage", data.selected);
      //consolele.log(location.state);
      // //consolele.log("aasd", window.history.state);
      navigate(`${location.pathname}?page=${data.selected + 1}`, {
        replace: true,
        state: { id: location.state ? location.state.id : "" },
      });
      window.scrollTo(0, 0);
    }
  };
  return (
    <div className="pagination-con">
      <ReactPaginate
        nextLabel={"NEXT"}
        previousLabel={"<<"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item-prev"
        previousLinkClassName="page-link-prev"
        nextClassName="page-item-next"
        nextLinkClassName="page-link-next"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
        initialPage={
          location.search.slice(-1) && location.search.indexOf("page") > -1
            ? location.search.slice(location.search.indexOf("=") + 1) - 1
            : 0
        }
        forcePage={
          location.search.slice(-1) && location.search.indexOf("page") > -1
            ? location.search.slice(location.search.indexOf("=") + 1) - 1
            : 0
        }
      />
    </div>
  );
};

export default Pagination;
