import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Header1 from "./components/Header1/Header1";
import Header2 from "./components/Header2/Header2";
import Footer from "./components/Footer/Footer";
import SmallHeader from "./components/SmallHeader/SmallHeader";
import SmallHeaderMobile from "./components/SmallHeaderMobile/SmallHeaderMobile";
import SetupClosest from "./pages/SetupClosest";
import SetupClosestComplete from "./pages/SetupClosestComplete";
import Login from "./pages/Login";
import Feed from "./pages/Feed";
import MyCloset from "./pages/MyCloset";
import AddItems from "./pages/AddItems";
import ScrollToTop from "./ScrollToTop";
import Shop from "./pages/Shop";
import Product from "./pages/Product";
import EditItems from "./pages/EditItems";
import Checkout from "./pages/Checkout";
import Confirmation from "./pages/Confirmation";
import Verify from "./pages/Verify";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import HowItWorks from "./pages/HowItWorks";
import QualityItemsOnly from "./pages/QualityItemsOnly";
import CleanYourCloset from "./pages/CleanYourCloset";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition";
import Hangers from "./pages/Hangers";
import Purchased from "./pages/Purchased";
import Orders from "./pages/Orders";
import Purchases from "./pages/Purchases";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import OrderConfirmation from "./pages/OrderConfirmation";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getuserInfo,
  reset,
  resetStatus,
} from "./components/StateSlices/getuserSlice";
import Membership from "./pages/Membership";
import SupportAdmin from "./components/ChatSupport/SupportAdmin";
import UploadImage from "./components/UploadFileImage/UploadImage";
import Solded from "./pages/Solded";
import SearchResults from "./components/Search/SearchResults";
import Marquee from "./components/HomeComponents/Marquee";
import LandingSection from "./components/HomeComponents/LandingSection";
import GeneralHomePage from "./pages/GeneralHomePage";
import NotFound from "./pages/NotFound";
import Closet from "./pages/Closet";
import Pagination from "./components/Pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import Spinner1 from "./components/Spinner1/Spinner1";

import "react-toastify/dist/ReactToastify.css";
import SeparateChat from "./components/ChatSupport/SupportAdmin/SeparateChat";
import AdminHome from "./admin/pages/AdminHome/AdminHome";
import Alert from "./components/Alert/Alert";
import PurchaseTableAccordion from "./components/PurchaseTable/PurchaseTableAccordion";
import Collapse, { Panel } from "rc-collapse";
import InfoCard from "./components/InfoCard/InfoCard";
import AdminMenu from "./components/AdminMenu/AdminMenu";
import Tabs from "./admin/components/Tabs/Tabs";
import MyPurchases from "./components/MyPurchases/MyPurchases";
import CMSFront from "./pages/CMSFront";
import GuestConfirmation from "./pages/GuestConfirmation";
import NewFooter from "./components/Footer/NewFooter";
import DynamicTitleUpdater from "./DynamicTitleUpdater";
import { getAllStaticContent } from "./components/StateSlices/staticContentSlice";
import LogoutPageNew from "./pages/LogoutPageNew";
import ScrollAnimation from "react-animate-on-scroll";
import MyClosetNew from "./components/MyClosetNew/MyClosetNew";

function RequireAuth({ children }) {
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  return status == "loading" ? (
    <Spinner1></Spinner1>
  ) : userInfo && userInfo.role === "admin" ? (
    children
  ) : (
    <NotFound />
  );
}

function RequireAuthUser({ children }) {
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  return status == "loading" ? (
    <Spinner1></Spinner1>
  ) : userInfo ? (
    children
  ) : (
    <Signup />
  );
}

function App() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const currentPath = pathSegments[pathSegments.length - 2];
  const currentPath2 = pathSegments[pathSegments.length - 3];
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { staticContentInfo, staticContentStatus } = useSelector(
    (state) => state.staticContent
  );
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  if (userInfo && (status !== "idle" || status !== "loading")) {
    window.dataLayer.push({
      event: "pageview",
      userEmail: userInfo.userDetailsId.userEmail,
      userId: userInfo.userDetailsId._id,
      userMembership: userInfo.userDetailsId.membership,
      checkoutId: "N/A",
      products: "N/A",
      hangersUsed: "N/A",
      hangersPurchased: "N/A",
      originalPrice: "N/A",
      creditPrice: "N/A",
    });
  } else if (status !== "idle" || status !== "loading") {
    window.dataLayer.push({
      event: "pageview",
      userEmail: "guest",
      userId: "guest",
      userMembership: "guest",
      checkoutId: "N/A",
      products: "N/A",
      hangersUsed: "N/A",
      hangersPurchased: "N/A",
      originalPrice: "N/A",
      creditPrice: "N/A",
    });
  }

  const [cart, setCart] = useState(
    JSON.parse(sessionStorage.getItem("closetcart"))
      ? JSON.parse(sessionStorage.getItem("closetcart"))
      : []
  );
  const [staticContent, setStaticContent] = useState(null);
  useEffect(() => {
    dispatch(resetStatus());
    setLoading(true);
    dispatch(getAllStaticContent({ values: "All" }));
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getuserInfo({ token: token }));
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (
        (userInfo.userDetailsId && userInfo.userDetailsId.status === "ban") ||
        (userInfo.userDetailsId && userInfo.userDetailsId.status === "pause")
      ) {
        localStorage.removeItem("clttoken");
        dispatch(reset());
        navigate("/");
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (staticContentInfo) {
      setStaticContent(staticContentInfo);
      setLoading(false);
    }
  }, [staticContentInfo]);

  return (
    <>
      <DynamicTitleUpdater />
      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <Spinner1></Spinner1>
      ) : (
        <div className="App" style={{ position: "relative" }}>
          {(currentPath && currentPath === "admin") ||
          (currentPath2 && currentPath2 === "admin") ? (
            ""
          ) : (
            <>
              <div style={{ position: "sticky", top: 0, zIndex: "999" }}>
                <Alert msg={"refer a friend and earn hangers!"} link="" />
              </div>
              <Header1 cart={cart} />
              <div className="small_header">
                <SmallHeaderMobile cart={cart} />
              </div>
              <div className="small_header2">
                <SmallHeader />
              </div>
              <Header2 />
            </>
          )}

          <ScrollToTop />

          <Routes>
            <Route
              path="/"
              exact={true}
              element={
                status !== "succeeded" &&
                status !== "idle" &&
                status !== "failed" ? (
                  <Spinner1 />
                ) : userInfo ? (
                  <Home content={staticContent} />
                ) : (
                  <>
                    <GeneralHomePage content={staticContent} />
                  </>
                )
              }
            />

            <Route path="how-it-works" exact={true} element={<HowItWorks />} />
            <Route
              path="quality-items-only"
              exact={true}
              element={<QualityItemsOnly />}
            />
            <Route
              path="clean-your-closet"
              exact={true}
              element={<CleanYourCloset />}
            />
            {/* <Route path="privacy" exact={true} element={<PrivacyPolicy />} /> */}
            {/* <Route
            path="terms-conditions1"
            exact={true}
            element={<TermsCondition />}
          /> */}
            <Route path="signup" exact={true} element={<Signup />} />
            <Route path="login" exact={true} element={<Login />} />
            <Route
              path="setup-closest"
              exact={true}
              element={<SetupClosest />}
            />
            <Route
              path="order-confirmation"
              exact={true}
              element={<OrderConfirmation />}
            />
            <Route
              path="setup-closest-complete"
              exact={true}
              element={<SetupClosestComplete />}
            />
            <Route
              path="shop"
              exact={true}
              element={<Shop setCartVal={setCart} cartVal={cart} />}
            />

            <Route
              path="user/*"
              // exact={true}
              element={
                // <RequireAuthUser>
                <MyClosetNew />
                // </RequireAuthUser>
              }
            />
            {/* <Route path="hanger" exact={true} element={<Hangers />} /> */}
            <Route path="add-items" exact={true} element={<AddItems />} />
            <Route
              path="checkout"
              exact={true}
              element={<Checkout setCart={setCart} />}
            />
            {/* <Route path="orders" exact={true} element={<Orders />} /> */}
            {/* <Route path="purchases" exact={true} element={<Purchases />} /> */}
            {/* <Route
            path="purchases"
            exact={true}
            element={<PurchaseTableAccordion />}
          /> */}
            {/* <Route path="profile" exact={true} element={<Profile />} /> */}
            {/* <Route path="edit-profile" exact={true} element={<EditProfile />} /> */}
            <Route
              path="confirmation"
              exact={true}
              element={<Confirmation />}
            />
            <Route
              path="guest-confirmation"
              exact={true}
              element={<GuestConfirmation />}
            />

            <Route
              path="forgotpassword"
              exact={true}
              element={<ForgotPassword />}
            />
            <Route
              path="resetpassword/:token"
              exact={true}
              element={<ResetPassword />}
            />
            <Route path="verify/:id" exact={true} element={<Verify />} />
            <Route
              path="product/:pname/:productId"
              exact={true}
              element={<Product setCartVal={setCart} cartVal={cart} />}
            />
            <Route
              path="purchased/:pname"
              exact={true}
              element={<Purchased />}
            />
            <Route path="solded/:pname" exact={true} element={<Solded />} />
            <Route
              path="editproduct/:pname"
              exact={true}
              element={<EditItems />}
            />
            <Route path="membership" exact={true} element={<Membership />} />
            <Route
              path="chat-support"
              exact={true}
              element={<SupportAdmin />}
            />
            <Route
              path="chat-support-ind/:receiverUserEmail/:productId"
              exact={true}
              element={<SeparateChat />}
            />
            {/* <Route path="upload-image" exact={true} element={<UploadImage />} /> */}
            <Route
              path="search/:query"
              exact={true}
              element={<SearchResults />}
            />
            <Route
              path="closet/:closetName"
              exact={true}
              element={<Closet />}
            />
            <Route path="pagination" exact={true} element={<Pagination />} />
            <Route
              path="admin/*"
              element={
                <RequireAuth>
                  <AdminHome />
                </RequireAuth>
              }
            />
            <Route path="/404" exact={true} element={<NotFound />} />
            <Route path="/:pageURL" exact={true} element={<CMSFront />} />
            <Route path="*" element={<NotFound />} />
            <Route
              path="/why-join-closest-closet"
              element={
                <LogoutPageNew
                  testimonialsContent={
                    staticContent && staticContent.testimonialsContent
                  }
                />
              }
            />
          </Routes>
          {/* <Routes>
          
        </Routes> */}
          {(currentPath && currentPath === "admin") ||
          (currentPath2 && currentPath2 === "admin") ? (
            ""
          ) : (
            // <Footer />
            // <></>
            // <></>
            <NewFooter content={staticContent && staticContent.footerContent} />
          )}

          {/* <Footer /> */}
        </div>
      )}
    </>
  );
}

export default App;
