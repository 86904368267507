import { configureStore } from "@reduxjs/toolkit";
import registerReducer from "./components/StateSlices/registerSlice";
import getuserReducer from "./components/StateSlices/getuserSlice";
import closetSetupReducer from "./components/StateSlices/closestsetupSlice";
import loginReducer from "./components/StateSlices/loginSlice";
import additemReducer from "./components/StateSlices/additemSlice";
import getallProductsReducer from "./components/StateSlices/getproductsSlice";
import getproductReducer from "./components/StateSlices/getproductSlice";
import updateitemReducer from "./components/StateSlices/updateitemSlice";
import deleteitemReducer from "./components/StateSlices/deleteitemSlice";
import addtocartReducer from "./components/StateSlices/addtocartSlice";
import checkoutReducer from "./components/StateSlices/checkoutSlice";
import verifyReducer from "./components/StateSlices/verifySlice";
import forgotPasswordReducer from "./components/StateSlices/forgotpasswordSlice";
import resetpasswordReducer from "./components/StateSlices/resetpasswordSlice";
import resendVerificationReducer from "./components/StateSlices/resendVerificationSlice";
import PurchasedReducer from "./components/StateSlices/getPurchasedSlice";
import OrdersReducer from "./components/StateSlices/getOrderSlice";
import BrandReducer from "./components/StateSlices/brandSlice";
import EditProfileReducer from "./components/StateSlices/editprofileSlice";
import DeleteCheckoutItemReducer from "./components/StateSlices/deleteCheckoutItemSlice";
import ShippingPriceReducer from "./components/StateSlices/getShippingSlice";
import newsletterReducer from "./components/StateSlices/newsletterSlice";
import membershipReducer from "./components/StateSlices/membershipUpgradeSlice";
import membershipCancelReducer from "./components/StateSlices/membershipCancelSlice";
import membershipHistoryReducer from "./components/StateSlices/membershiphistory";
import soldOrdersReducer from "./components/StateSlices/getSoldProductsSlice";
import soldedReducer from "./components/StateSlices/getSoldedSlice";
import searchReducer from "./components/StateSlices/searchSlice";
import closetReducer from "./components/StateSlices/getClosetSlice";
import hangerHistoryReducer from "./components/StateSlices/hangerHistorySlice";
import nonUserCheckoutReducer from "./components/StateSlices/nonCheckoutSlice";
import reactivateitemReducer from "./components/StateSlices/reactivateitemSlice";
import homeInfoReducer from "./components/StateSlices/getHomeSlice";
import cardUpgradeReducer from "./components/StateSlices/cardUpgradeSlice";
import verifyCoupenReducer from "./components/StateSlices/verifyCoupenSlice";
import adminReducer from "./components/StateSlices/adminSlice";
import referLinkReducer from "./components/StateSlices/referLinkSlice";
import commentReducer from "./components/StateSlices/commentSlice";
import productReportReducer from "./components/StateSlices/productReportSlice";
import productRatingReducer from "./components/StateSlices/productRatingSlice";
import closetRatingReducer from "./components/StateSlices/closetRatingSlice";
import userListReducer from "./components/StateSlices/userListSlice";
import chatHistoryReducer from "./components/StateSlices/chatHistorySlice";
import closetProductReducer from "./components/StateSlices/getClosetProductSlice";
import closetcommentReducer from "./components/StateSlices/closetCommentSlice";
import transferProductReducer from "./components/StateSlices/transferProductSlice";
import handleLikeReducer from "./components/StateSlices/handleLikeSlice";
import cmsReducer from "./components/StateSlices/cmsSlice";
import staticContentReducer from "./components/StateSlices/staticContentSlice";
import topClosetsReducer from "./components/StateSlices/getTopClosets";
import coupenReducer from "./components/StateSlices/coupenSlice";
import adminDataExportSlice from "./components/StateSlices/adminDataExportSlice";
import fulfillReducer from "./components/StateSlices/fulfillSlice";
import analyticsReducer from "./components/StateSlices/analyticsSlice";
import analyticsDataExportReducer from "./components/StateSlices/analyticsDataExportSlice";
import videoUploadMulterReducer from "./components/StateSlices/videoUploadMulterSlice";
import userInfoForAdminReducer from "./components/StateSlices/getUserSliceForAdmin";
import userBannerInfoReducer from "./components/StateSlices/getUserBannerInfo";

export default configureStore({
  reducer: {
    homeInfo: homeInfoReducer,
    register: registerReducer,
    user: getuserReducer,
    closet: closetSetupReducer,
    login: loginReducer,
    add: additemReducer,
    product: getallProductsReducer,
    getproduct: getproductReducer,
    update: updateitemReducer,
    delete: deleteitemReducer,
    reactivate: reactivateitemReducer,
    cart: addtocartReducer,
    checkout: checkoutReducer,
    verify: verifyReducer,
    forgot: forgotPasswordReducer,
    reset: resetpasswordReducer,
    resend: resendVerificationReducer,
    purchased: PurchasedReducer,
    orders: OrdersReducer,
    brand: BrandReducer,
    editprofile: EditProfileReducer,
    checkoutdelete: DeleteCheckoutItemReducer,
    shippingprice: ShippingPriceReducer,
    newsletter: newsletterReducer,
    membership: membershipReducer,
    membershipcancel: membershipCancelReducer,
    membershiphistory: membershipHistoryReducer,
    soldorders: soldOrdersReducer,
    solded: soldedReducer,
    search: searchReducer,
    closetInfo: closetReducer,
    hangerhistory: hangerHistoryReducer,
    nonusercheckout: nonUserCheckoutReducer,
    card: cardUpgradeReducer,
    verifycoupen: verifyCoupenReducer,
    admin: adminReducer,
    referLink: referLinkReducer,
    comment: commentReducer,
    userlist: userListReducer,
    productReport: productReportReducer,
    productRating: productRatingReducer,
    chathistory: chatHistoryReducer,
    closetproduct: closetProductReducer,
    closetRating: closetRatingReducer,
    transferproduct: transferProductReducer,
    closetcomment: closetcommentReducer,
    like: handleLikeReducer,
    cms: cmsReducer,
    staticContent: staticContentReducer,
    topClosets: topClosetsReducer,
    coupen: coupenReducer,
    getDataExport: adminDataExportSlice,
    fulfilled: fulfillReducer,
    analytics: analyticsReducer,
    getAnalyticsDataExport: analyticsDataExportReducer,
    videoUpload: videoUploadMulterReducer,
    userForAdmin: userInfoForAdminReducer,
    userbannerinfo: userBannerInfoReducer,
  },
});
