import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getuserInfo } from "../components/StateSlices/getuserSlice";
import {
  updateItem,
  resetItem,
} from "../components/StateSlices/updateitemSlice";
import { getProduct, reset } from "../components/StateSlices/getproductSlice";
import {
  getUserList,
  resetUserList,
} from "../components/StateSlices/userListSlice";
import { addBrand, resetBrand } from "../components/StateSlices/brandSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../components/Spinner/Spinner";
import axios from "axios";
import Select, { components } from "react-select";

import "react-toastify/dist/ReactToastify.css";
import "./AddItems.css";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "3px",
  borderBottom: "2px solid #F26654",
};

const groupLabelStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#F26654",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const EditItems = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { pstatus, productInfo, images, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );
  const { userListStatus, userListError, userList } = useSelector(
    (state) => state.userlist
  );
  const { itemStatus, itemInfo, itemError } = useSelector(
    (state) => state.update
  );
  const {
    brandStatus,
    brandInfo,
    brandError,
    categoryInfo,
    signature,
    timestamp,
    sizeInfo,
  } = useSelector((state) => state.brand);

  const [feature, setFeature] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [brandName, setBrandName] = useState("");
  const [brandErrorDisplay, setBrandErrorDisplay] = useState("");
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [colors, setColors] = useState([]);
  const [colorError, setColorError] = useState("");
  const [id, setId] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [from, setFrom] = useState("user");
  const [userSelected, setUserSelected] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      itemName: "",
      itemDesc: "",
      brand: "",
      category: [],
      size: "",
      itemPrice: "",
      condition: "",
      images: [],
      quantity: 1,
      shipping: "true",
      personalColor: "",
      hangerCredits: 0,
    },
    validationSchema: Yup.object({
      itemName: Yup.string().required("Please enter Item Name"),
      itemDesc: Yup.string().required("Please enter Item Description"),
      brand: Yup.string().required("Please select Brand"),
      category: Yup.array().min(1, "Please select Category"),
      size: Yup.string().required("Please select the Size"),
      itemPrice: Yup.string().required("Please enter Item Price"),
      condition: Yup.string().required("Please enter Item Condition"),
      quantity: Yup.number().min(1).required("Please enter valid Quantity"),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);
      let token = localStorage.getItem("clttoken");
      if (token) {
        setSpinner(true);
        postDetails(values, setSubmitting, setValues, token);
      } else {
        navigate("/");
      }

      return false;
    },
  });

  useEffect(() => {
    if (pstatus == "loading" || pstatus == "idle") {
      console.log(pstatus);
      setSpinner(true);
    } else {
      setSpinner(false);
    }
  }, [pstatus]);

  useEffect(() => {
    dispatch(reset());
    let token = localStorage.getItem("clttoken");
    if (location.state && location.state.id) {
      if (!userInfo) {
        dispatch(getuserInfo({ token }));
      }
    } else {
      navigate("/user/my-closet", { replace: true });
    }
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token && !userInfo) {
      dispatch(getuserInfo({ token }));
    } else if (!token) {
      navigate("/login", { replace: true });
    } else {
      if (
        userInfo.totalProduct.includes(location.state.id) ||
        userInfo.role === "admin"
      ) {
        dispatch(getProduct(location.state.id));
        if (location.state && location.state.from) {
          setFrom("admin");
        }
        dispatch(
          addBrand({ token, values: { brandName }, requestType: "get" })
        );
      } else {
        navigate("/user/my-closet", { replace: true });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    console.log(productInfo);
    if (productInfo && pstatus == "succeeded" && sizeList.length > 0) {
      let token = localStorage.getItem("clttoken");
      if (usersList.length === 0) {
        dispatch(getUserList({ token }));
        // setSpinner(true);
      }

      console.log(closetInfo);
      let currentCat;
      let categoryFormatted = productInfo.productStats.category.map((ctg) => {
        if (
          ctg === "Men" ||
          ctg === "Women" ||
          ctg === "Girl" ||
          ctg === "Boy" ||
          ctg === "Other"
        ) {
          currentCat = ctg;
          return null;
        }

        let id =
          category &&
          category
            .flatMap((section) => section.options)
            .find((option) => option.label === ctg);
        // console.log(id)
        if (currentCat === "Men") {
          return {
            label: ctg,
            value: ctg + "M",
            pt: ["2"],
            id: id ? id.id : null,
          };
        } else if (currentCat === "Women") {
          return {
            label: ctg,
            value: ctg + "W",
            pt: ["1"],
            id: id ? id.id : null,
          };
        } else if (currentCat === "Boy") {
          return {
            label: ctg,
            value: ctg + "B",
            pt: ["3"],
            id: id ? id.id : null,
          };
        } else if (currentCat === "Girl") {
          return {
            label: ctg,
            value: ctg + "G",
            pt: ["4"],
            id: id ? id.id : null,
          };
        } else if (currentCat === "Other") {
          return { label: ctg, value: ctg + "O", pt: ["5"] };
        } else {
          return { label: ctg, value: ctg, pt: ["1", "2", "3", "4", "5"] };
        }
      });
      categoryFormatted = categoryFormatted.filter((ctg) => ctg !== null);
      console.log(categoryFormatted);
      formik.setFieldValue("itemName", productInfo.productName);
      formik.setFieldValue("itemDesc", productInfo.productDesc);
      formik.setFieldValue("brand", productInfo.productStats.brand);
      formik.setFieldValue("category", categoryFormatted);
      formik.setFieldValue("size", productInfo.productStats.size);
      formik.setFieldValue("itemPrice", productInfo.productPrice);
      formik.setFieldValue("condition", productInfo.productStats.condition);
      formik.setFieldValue("quantity", productInfo.productStock);
      formik.setFieldValue("shipping", productInfo.productShipping);
      formik.setFieldValue("hangerCredits", productInfo.productHanger);
      let definedColors = [
        "red",
        "white",
        "pink",
        "yellow",
        "black",
        "orange",
        "blue",
        "gold",
        "navy",
        "brown",
        "purple",
        "grey",
        "green",
        "tan",
        "teal",
        "creame",
        "silver",
      ];
      let pcolor = productInfo.productStats.color.filter(
        (element) => !definedColors.includes(element)
      );
      if (pcolor.length > 0) {
        formik.setFieldValue("personalColor", pcolor.join(","));
      }
      let dcolor = definedColors.filter((element) =>
        productInfo.productStats.color.includes(element)
      );
      if (dcolor.length > 0) {
        console.log(dcolor);
        setColors(dcolor);
      }

      setSelectedCondition({
        label: productInfo.productStats.condition,
        value: productInfo.productStats.condition,
      });
      setSelectedSize({
        label: productInfo.productStats.size,
        value: productInfo.productStats.condition,
      });
      setSelectedBrand({
        label: productInfo.productStats.brand,
        value: productInfo.productStats.condition,
      });
      if (productInfo.productStock > 0) {
        visibleSetChecked(true);
      }
      setEImages(productInfo.productImage);
    }
  }, [productInfo, sizeList]);

  if (userList.length > 0) {
    let users = userList.map((user) => ({
      label: `${user.userFName + " " + user.userLName} - ${user.userEmail}`,
      value: user.userMasterId,
    }));
    setUsersList(users);
    dispatch(resetUserList());
  }

  if (brandInfo) {
    let temp = brandInfo.map((brandVal) => {
      return { value: brandVal.brandName, label: brandVal.brandName };
    });
    let menCat = [];
    let womenCat = [];
    let boysCat = [];
    let girlsCat = [];
    let notApp = [];

    let temp3 = categoryInfo.map((brandVal) => {
      if (brandVal.categoryParent.includes("1")) {
        womenCat.push({
          value: brandVal.categoryName + "W",
          label: brandVal.categoryName,
          pt: ["1"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("2")) {
        menCat.push({
          value: brandVal.categoryName + "M",
          label: brandVal.categoryName,
          pt: ["2"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("3")) {
        boysCat.push({
          value: brandVal.categoryName + "B",
          label: brandVal.categoryName,
          pt: ["3"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("4")) {
        girlsCat.push({
          value: brandVal.categoryName + "G",
          label: brandVal.categoryName,
          pt: ["4"],
          id: brandVal._id,
        });
      }
      if (brandVal.categoryParent.includes("5")) {
        notApp.push({
          value: brandVal.categoryName + "O",
          label: brandVal.categoryName,
          pt: ["5"],
          id: brandVal._id,
        });
      }
      return {
        value: brandVal.categoryName,
        label: brandVal.categoryName,
        pt: brandVal.categoryParent,
        id: brandVal._id,
      };
    });
    let temp2 = [
      {
        label: "Women",
        options: womenCat,
      },
      {
        label: "Men",
        options: menCat,
      },
      {
        label: "Girls",
        options: girlsCat,
      },
      {
        label: "Boys",
        options: boysCat,
      },
      {
        label: "Other",
        options: notApp,
      },
    ];
    console.log(temp);
    setBrands([...temp]);
    setCategory([...temp2]);
    formik.setFieldValue("brand", brandName);
    setSelectedBrand({ label: brandName, value: brandName });
    setSizeList(sizeInfo);
    dispatch(resetBrand());
    closeModal();
  }

  useEffect(() => {
    if (brandError) {
      setBrandErrorDisplay(brandError.message);
      dispatch(resetBrand());
    }
  }, [brandError]);

  useEffect(() => {
    if (brandStatus === "loading") {
      console.log(brandStatus);
      setSpinner(true);
    } else {
      setSpinner(false);
    }
  }, [brandStatus]);

  const [spinner, setSpinner] = useState(false);
  const [visibleChecked, visibleSetChecked] = useState(true);

  const [eimages, setEImages] = useState([]);

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const hangerToastOption = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (itemInfo) {
    toast.success("Item Updated Successfully", toastOption);
    if (
      productInfo.productHanger != itemInfo.productHanger ||
      productInfo.productStock != itemInfo.productStock
    ) {
      toast.success(
        `${
          productInfo.productStock * productInfo.productHanger >
          itemInfo.productStock * itemInfo.productHanger
            ? "-"
            : "+"
        }${Math.abs(
          productInfo.productStock * productInfo.productHanger -
            itemInfo.productStock * itemInfo.productHanger
        )} Hanger Updated Successfully`,
        hangerToastOption
      );
      let token = localStorage.getItem("clttoken");
      dispatch(getuserInfo({ token }));
    }

    dispatch(resetItem());

    navigate(`/product/${itemInfo.productName}/${location.state.id}`, {
      state: { id: location.state.id },
    });
  }

  useEffect(() => {
    if (itemError) {
      toast.error("Something Went Wrong Please Try Again", toastOption);
      setSpinner(false);
    }
  }, [itemError]);

  const postDetails = (values, setSubmitting, setValues, token) => {
    let payload = { ...values };

    const uploaders = eimages.map((file, index) => {
      // Initial FormData
      if (typeof file !== "string") {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "closet-closest");
        formData.append("cloud_name", "closet-closest");
        formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
        formData.append("signature", signature);
        formData.append("timestamp", timestamp);
        formData.append("api_key", "738997887579849");

        return axios
          .post(
            "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
            formData,
            {
              headers: { "X-Requested-With": "XMLHttpRequest" },
            }
          )
          .then((response) => {
            const data = response.data;
            const fileURL = data.secure_url; // You should store this URL for future references in your app
            if (index === feature)
              payload = { ...values, images: [fileURL, ...payload.images] };
            else payload = { ...values, images: [...payload.images, fileURL] };
          })
          .catch((e) => {
            console.log(e);
            throw new Error("Something went Wrong!!!!");
          });
      } else {
        if (index === feature)
          payload = { ...values, images: [file, ...payload.images] };
        else payload = { ...values, images: [...payload.images, file] };
      }
    });

    axios
      .all(uploaders)
      .then(() => {
        setValues(payload);
        payload.color = colors;
        payload.role = from;
        console.log(payload);
        if (userSelected && userSelected.value !== closetInfo.masterId) {
          payload.transfer = userSelected.value;
        }
        dispatch(updateItem({ token, values: payload, id: location.state.id }));
        setSubmitting(false);
      })
      .catch((e) => {
        toast.error("Something Went Wrong Please Try Again", toastOption);
        dispatch(
          addBrand({ token, values: { brandName }, requestType: "get" })
        );
      });
    return;
  };

  const removeImg = (id) => {
    console.log(id);
    if (feature === id) setFeature(0);
    let updatedImg = eimages.filter((img, index) => index !== id);
    setEImages([...updatedImg]);
  };
  function openModal() {
    setBrandName("");
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const brandHandle = (event) => {
    event.preventDefault();
    let token = localStorage.getItem("clttoken");
    setSpinner(true);
    setBrandErrorDisplay("");
    dispatch(addBrand({ token, values: { brandName }, requestType: "post" }));
  };

  const options = [
    { value: "New", label: "New" },
    { value: "Like New", label: "Like New" },
    { value: "Good", label: "Good" },
    { value: "Fair", label: "Fair" },
    { value: "Poor", label: "Poor" },
  ];

  const optionSizeMen = [
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
    { value: "XL", label: "XL" },
    { value: "2X", label: "2X" },
    { value: "3X", label: "3X" },
    { value: "4X", label: "4X" },
    { value: "5X", label: "5X" },
    { value: "6X", label: "6X" },
    { value: "Shoes - 8.5", label: "Shoes - 8.5" },
    { value: "XShoes - 9", label: "Shoes - 9" },
    { value: "Shoes - 9.5", label: "Shoes - 9.5" },
    { value: "Shoes - 10.5", label: "Shoes - 10.5" },
    { value: "Shoes - 10", label: "Shoes - 10" },
    { value: "Shoes - 11", label: "Shoes - 11" },
    { value: "Shoes - 11.5", label: "Shoes - 11.5" },
    { value: "Shoes - 12", label: "Shoes - 12" },
    { value: "Shoes - 12.5", label: "Shoes - 12.5" },
    { value: "Shoes - 13", label: "Shoes - 13" },
    { value: "Shoes - 13.5", label: "Shoes - 13.5" },
    { value: "Shoes - 14", label: "Shoes - 14" },
    { value: "Shoes - 14.5", label: "Shoes - 14.5" },
    { value: "Shoes - 15", label: "Shoes - 15" },
    { value: "Shoes - 15.5", label: "Shoes - 15.5" },
    { value: "Shoes - 16", label: "Shoes - 16" },
    {
      value: "Shirt - XXS",
      label: "Shirt - XXS",
    },
    {
      value: "Shirt - XS",
      label: "Shirt - XS",
    },
    {
      value: "Shirt - Small",
      label: "Shirt - Small",
    },
    {
      value: "Shirt - Medium",
      label: "Shirt - Medium",
    },
    {
      value: "Shirt - Large",
      label: "Shirt - Large",
    },
    {
      value: "Shirt - XL",
      label: "Shirt - XL",
    },
    {
      value: "Shirt - 2X",
      label: "Shirt - 2X",
    },
    {
      value: "Shirt - 3X",
      label: "Shirt - 3X",
    },
    {
      value: "Shirt - 4X",
      label: "Shirt - 4X",
    },
    {
      value: "Shirt - Neck 14.5",
      label: "Shirt - Neck 14.5",
    },
    {
      value: "Shirt - Neck 15",
      label: "Shirt - Neck 15",
    },
    {
      value: "Shirt - Neck 15.5",
      label: "Shirt - Neck 15.5",
    },
    {
      value: "Shirt - Neck 16",
      label: "Shirt - Neck 16",
    },
    {
      value: "Shirt - Neck 16.5",
      label: "Shirt - Neck 16.5",
    },
    {
      value: "Shirt - Neck 17",
      label: "Shirt - Neck 17",
    },
    {
      value: "Shirt - Neck 17.5",
      label: "Shirt - Neck 17.5",
    },
    {
      value: "Shirt - Neck 18",
      label: "Shirt - Neck 18",
    },
    { value: "Waist - 1", label: "Waist - 1" },
    { value: "Waist - 1", label: "Waist - 1" },
    { value: "Waist - 3", label: "Waist - 3" },
    { value: "Waist - 5", label: "Waist - 5" },
    { value: "Waist - 7", label: "Waist - 7" },
    { value: "Waist -09", label: "Waist -09" },
    { value: "Waist - 11", label: "Waist - 11" },
    { value: "Waist - 13", label: "Waist - 13" },
    { value: "Waist - 15", label: "Waist - 15" },
    { value: "Waist - 22", label: "Waist - 22" },
    { value: "Waist - 23", label: "Waist - 23" },
    { value: "Waist - 24", label: "Waist - 24" },
    { value: "Waist - 25", label: "Waist - 25" },
    { value: "Waist - 26", label: "Waist - 26" },
    { value: "Waist - 27", label: "Waist - 27" },
    { value: "Waist - 28", label: "Waist - 28" },
    { value: "Waist - 29", label: "Waist - 29" },
    { value: "Waist - 30", label: "Waist - 30" },
    { value: "Waist - 31", label: "Waist - 31" },
    { value: "Waist - 32", label: "Waist - 32" },
    { value: "Waist - 33", label: "Waist - 33" },
    { value: "Waist - 34", label: "Waist - 34" },
    { value: "Waist - 35", label: "Waist - 35" },
    { value: "Waist - 37", label: "Waist - 37" },
    { value: "Waist - 39", label: "Waist - 39" },
    { value: "Waist - 41", label: "Waist - 41" },
    { value: "Waist - 43", label: "Waist - 43" },
    { value: "Shirt - Neck 14.5", label: "Shirt - Neck 14.5" },
    { value: "Shirt - Neck 15", label: "Shirt - Neck 15" },
    { value: "Shirt - Neck 15.5", label: "Shirt - Neck 15.5" },
    { value: "Shirt - Neck 16", label: "Shirt - Neck 16" },
    { value: "Shirt - Neck 16.5", label: "Shirt - Neck 16.5" },
    { value: "Shirt - Neck 17", label: "Shirt - Neck 17" },
    { value: "Shirt - Neck 17.5", label: "Shirt - Neck 17.5" },
    { value: "Shirt - Neck 18", label: "Shirt - Neck 18" },
  ];

  const optionsSizeWoemen = [
    { value: "XXS", label: "XXS" },
    { value: "XS", label: "XS" },
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
    { value: "XL", label: "XL" },
    { value: "2X", label: "2X" },
    { value: "3X", label: "3X" },
    { value: "4X", label: "4X" },
    { value: "Shoes - 5", label: "Shoes - 5" },
    { value: "Shoes - 5.5", label: "Shoes - 5.5" },
    { value: "Shoes - 6", label: "Shoes - 6" },
    { value: "Shoes - 6.5", label: "Shoes - 6.5" },
    { value: "Shoes - 7", label: "Shoes - 7" },
    { value: "Shoes - 7.5", label: "Shoes - 7.5" },
    { value: "Shoes - 8", label: "Shoes - 8" },
    { value: "Shoes - 8.5", label: "Shoes - 8.5" },
    { value: "XShoes - 9", label: "Shoes - 9" },
    { value: "Shoes - 9.5", label: "Shoes - 9.5" },
    { value: "Shoes - 10.5", label: "Shoes - 10.5" },
    { value: "Shoes - 10", label: "Shoes - 10" },
    { value: "Shoes - 11", label: "Shoes - 11" },
    { value: "Shoes - 11.5", label: "Shoes - 11.5" },
    { value: "Shoes - 12", label: "Shoes - 12" },
    { value: "Shoes - 12.5", label: "Shoes - 12.5" },
    { value: "Shoes - 13", label: "Shoes - 13" },
    { value: "Shoes - 13.5", label: "Shoes - 13.5" },
    { value: "Shoes - 14", label: "Shoes - 14" },
    { value: "Shoes - 14.5", label: "Shoes - 14.5" },
    { value: "Shoes - 15", label: "Shoes - 15" },
    { value: "Shoes - 15.5", label: "Shoes - 15.5" },
    { value: "Shoes - 16", label: "Shoes - 16" },
    { value: "Shirt / Top - XXS", label: "Shirt / Top - XXS" },
    { value: "Shirt / Top - XS", label: "Shirt / Top - XS" },
    { value: "Shirt / Top - Small", label: "Shirt / Top - Small" },
    { value: "Shirt / Top - Medium", label: "Shirt / Top - Medium" },
    { value: "Shirt / Top - Large", label: "Shirt / Top - Large" },
    { value: "Shirt / Top - XL", label: "Shirt / Top - XL" },
    { value: "Shirt / Top - 2X", label: "Shirt / Top - 2X" },
    { value: "Shirt /Top - 3X", label: "Shirt /Top - 3X" },
    { value: "Shirt /Top - 4X", label: "Shirt /Top - 4X" },
    { value: "Dress - 0", label: "Dress - 0" },
    { value: "Dress - 2", label: "Dress - 2" },
    { value: "Dress - 4", label: "Dress - 4" },
    { value: "Dress - 6", label: "Dress - 6" },
    { value: "Dress - 8", label: "Dress - 8" },
    { value: "Dress - 10", label: "Dress - 10" },
    { value: "Dress - 12", label: "Dress - 12" },
    { value: "Dress - 14", label: "Dress - 14" },
    { value: "Dress - 16", label: "Dress - 16" },
    { value: "Dress - 18", label: "Dress - 18" },
    { value: "Dress - 20", label: "Dress - 20" },
    { value: "Dress - 22", label: "Dress - 22" },
    { value: "Dress - 24", label: "Dress - 24" },
    { value: "Dress - 26", label: "Dress - 26" },
    { value: "Dress - 28", label: "Dress - 28" },
    { value: "Dress - 30", label: "Dress - 30" },
    { value: "Dress - 32", label: "Dress - 32" },
    { value: "Dress - 34", label: "Dress - 34" },
    { value: "Dress - 36", label: "Dress - 36" },
    { value: "Waist - 0", label: "Waist - 0" },
    { value: "Waist - 2", label: "Waist - 2" },
    { value: "Waist - 4", label: "Waist - 4" },
    { value: "Waist - 6", label: "Waist - 6" },
    { value: "Waist - 8", label: "Waist - 8" },
    { value: "Waist -10", label: "Waist -10" },
    { value: "Waist - 12", label: "Waist - 12" },
    { value: "Waist - 14", label: "Waist - 14" },
    { value: "Waist - 16", label: "Waist - 16" },
    { value: "Waist - 23", label: "Waist - 23" },
    { value: "Waist - 24", label: "Waist - 24" },
    { value: "Waist - 25", label: "Waist - 25" },
    { value: "Waist - 26", label: "Waist - 26" },
    { value: "Waist - 27", label: "Waist - 27" },
    { value: "Waist - 28", label: "Waist - 28" },
    { value: "Waist - 29", label: "Waist - 29" },
    { value: "Waist - 30", label: "Waist - 30" },
    { value: "Waist - 31", label: "Waist - 31" },
    { value: "Waist - 32", label: "Waist - 32" },
    { value: "Waist - 33", label: "Waist - 33" },
    { value: "Waist - 34", label: "Waist - 34" },
    { value: "Waist - 35", label: "Waist - 35" },
    { value: "Waist - 36", label: "Waist - 36" },
    { value: "Waist - 38", label: "Waist - 38" },
    { value: "Waist - 40", label: "Waist - 40" },
    { value: "Waist - 42", label: "Waist - 42" },
    { value: "Waist - 44", label: "Waist - 44" },
  ];

  const optionsSizeKids = [
    { value: "Kids - XS", label: "Kids - XS" },
    { value: "Kids - S", label: "Kids - S" },
    { value: "Kids - M", label: "Kids - M" },
    { value: "Kids - L", label: "Kids - L" },
    { value: "Kids - XL", label: "Kids - XL" },
    { value: "Kids - XXL", label: "Kids - XXL" },
    { value: "Kids - Newborn", label: "Kids - Newborn" },
    { value: "Kids - 0-3 Months", label: "Kids - 0-3 Months" },
    { value: "Kids - 3-6 Months", label: "Kids - 3-6 Months" },
    { value: "Kids - 6-9 Months", label: "Kids - 6-9 Months" },
    { value: "Kids - 9-12 Months", label: "Kids - 9-12 Months" },
    { value: "Kids - 12-18 Months", label: "Kids - 12-18 Months" },
    { value: "Kids - 18-24 Months", label: "Kids - 18-24 Months" },
    { value: "Kids - 2T", label: "Kids - 2T" },
    { value: "Kids - 3T", label: "Kids - 3T" },
    { value: "Kids - 4T", label: "Kids - 4T" },
    { value: "Kids - 5T", label: "Kids - 5T" },
    { value: "Kids - 6T", label: "Kids - 6T" },
    { value: "Kids - 2", label: "Kids - 2" },
    { value: "Kids - 3", label: "Kids - 3" },
    { value: "Kids - 4", label: "Kids - 4" },
    { value: "Kids - 5", label: "Kids - 5" },
    { value: "Kids - 6", label: "Kids - 6" },
    { value: "Kids - 8", label: "Kids - 8" },
    { value: "Kids - 10", label: "Kids - 10" },
    { value: "Kids - 12", label: "Kids - 12" },
    { value: "Kids - 14", label: "Kids - 14" },
    { value: "Kids - 16", label: "Kids - 16" },
    { value: "Kids - 18", label: "Kids - 18" },
    { value: "Kids - 20", label: "Kids - 20" },
    { value: "Shoes - 0", label: "Shoes - 0" },
    { value: "Shoes - .5", label: "Shoes - .5" },
    { value: "Shoes - 1", label: "Shoes - 1" },
    { value: "Shoes - 1.5", label: "Shoes - 1.5" },
    { value: "Shoes - 2", label: "Shoes - 2" },
    { value: "Shoes - 2.5", label: "Shoes - 2.5" },
    { value: "Shoes - 3", label: "Shoes - 3" },
    { value: "Shoes - 3.5", label: "Shoes - 3.5" },
    { value: "Shoes - 4", label: "Shoes - 4" },
    { value: "Shoes - 4.5", label: "Shoes - 4.5" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: "LATO",
      borderColor: state.isFocused ? "#F26654" : "inherit",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "LATO",
    }),
  };

  const handleColor = (color) => {
    console.log(color);
    setColorError("");
    if (colors.includes(color)) {
      let updatedColors = colors.filter((clr) => clr !== color);
      setColors([...updatedColors]);
    } else {
      setColors((prevState) => [...prevState, color]);
      console.log(colors);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setEImages((prevImg) => [...prevImg, ...e.dataTransfer.files]);
    }
  };

  useEffect(() => {
    if (formik.values.category.length > 0) {
      let tmpsizeoptions = [];
      let ptpresent = [];
      let ptpresent2 = formik.values.category.map((cat) => {
        if (cat.pt && cat.pt.includes("1")) ptpresent.push("1");
        if (cat.pt && cat.pt.includes("2")) ptpresent.push("2");
        if (cat.pt && cat.pt.includes("3")) ptpresent.push("3");
        if (cat.pt && cat.pt.includes("4")) ptpresent.push("4");
        if (cat.pt && cat.pt.includes("5")) ptpresent.push("5");
        return cat.pt ? cat.pt.toString() : "";
      });
      tmpsizeoptions =
        sizeList.length > 0
          ? sizeList.reduce((result, item) => {
              if (
                formik.values.category.some((condition) =>
                  item.categoryReference.includes(condition.id)
                ) &&
                ptpresent.some((pt) => item.categoryParent.includes(pt))
              ) {
                result.push({ value: item.sizeName, label: item.sizeName });
              }
              return result;
            }, [])
          : [];
      // console.log(sizes)
      console.log(formik.values.category);
      console.log(ptpresent);
      // if (ptpresent.includes("1")) {
      //   tmpsizeoptions = [...tmpsizeoptions, ...optionsSizeWoemen];
      // }
      // if (ptpresent.includes("2")) {
      //   tmpsizeoptions = [...tmpsizeoptions, ...optionSizeMen];
      // }
      // if (ptpresent.includes("3") || ptpresent.includes("4"))
      //   tmpsizeoptions = [...tmpsizeoptions, ...optionsSizeKids];
      // if (ptpresent.includes("5"))
      tmpsizeoptions = [
        ...tmpsizeoptions,
        {
          value: "Other (Size In Description)",
          label: "Other (Size In Description)",
        },
      ];
      // let w = tmpsizeoptions.filter(
      //   (tag, index, array) =>
      //     array.findIndex((t) => t.value == tag.value) == index
      // );
      // console.log(w);
      setSizeOptions([...tmpsizeoptions]);
    } else {
      setSelectedSize("");
      setSizeOptions([]);
      formik.setFieldValue("size", "");
    }
  }, [formik.values.category]);
  return (
    <div className="closest_setup_container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "400px",
            maxHeight: "200px",
            margin: "auto",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            className="btn dark-btn"
            style={{ padding: "4px 8px", borderRadius: "2px" }}
            onClick={closeModal}
          >
            X
          </button>
        </div>

        <form onSubmit={brandHandle}>
          <div className="input_container" style={{ marginBottom: "10px" }}>
            <label htmlFor="" className="item_title">
              Brand Name
            </label>
            <input
              type="text"
              id="brandName"
              name="brandName"
              onChange={(e) => setBrandName(e.target.value)}
              value={brandName}
              style={{ padding: "8px" }}
            />
          </div>
          <div
            className="brand_error"
            style={{ color: "red", marginBottom: "10px" }}
          >
            {brandErrorDisplay}
          </div>
          <button
            className="btn dark-btn"
            type="submit"
            style={{ borderRadius: "2px", marginRight: 0 }}
          >
            Add Brand
          </button>
        </form>
      </Modal>

      <div className="closest_setup">
        <h1 className="section_title">Edit Items</h1>
        <br></br>
        {from === "admin" && (
          <div className="select_container" style={{ fontFamily: "LATO" }}>
            <div style={{ marginBottom: ".5rem" }}>
              <label
                htmlFor=""
                className="item_title h3"
                style={{ marginBottom: ".5rem" }}
              >
                User Transfer
              </label>
            </div>
            <Select
              name="userSelected"
              options={usersList.sort((a, b) => a.label.localeCompare(b.label))}
              onChange={(selectedOption) => {
                setUserSelected(selectedOption);
              }}
              value={userSelected}
              styles={customStyles}
            />
          </div>
        )}
        <br></br>
        <form onSubmit={formik.handleSubmit}>
          <div className="closest_setup_form">
            {from === "admin" && (
              <>
                <div className="personal_info_container">
                  <div className="input_container">
                    <label htmlFor="hangerCredits" className="item_title">
                      Hanger Credits
                    </label>
                    <input
                      type="text"
                      id="hangerCredits"
                      name="hangerCredits"
                      onChange={formik.handleChange}
                      value={formik.values.hangerCredits}
                    />
                    {/* {formik.touched.itemPrice && formik.errors.itemPrice ? (
                      <small className="form-text text-danger">
                        {formik.errors.itemPrice}
                      </small>
                    ) : null} */}
                  </div>
                </div>
                <br></br>
              </>
            )}

            {eimages.length > 0 ? (
              <div className="preview_image">
                <div className="item_title">
                  Image Preview - Please Select the Feature Photo
                </div>
                <div className="preview_image_container">
                  <div
                    className="img_name_cont"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      margin: "10px 0px",
                    }}
                  >
                    {eimages.map((image, index) => {
                      return (
                        <div className={`img_icon_cont`} key={index}>
                          <div className="img_name">
                            <div
                              className="cross"
                              onClick={() => removeImg(index)}
                            >
                              X
                            </div>
                            <img
                              className={`${
                                feature === index ? "feature" : ""
                              }`}
                              onClick={() => setFeature(index)}
                              src={
                                typeof image === "string"
                                  ? image
                                  : URL.createObjectURL(image)
                              }
                              alt={image.name}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="img_upload_container">
              <div className="file_icon_container">
                <div className="item_title">Take or Choose Photos</div>
                <label
                  htmlFor="closet_icon"
                  className={`file_upload ${dragActive ? "drag-active" : ""}`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="icon_container add_icon_container">
                    <i class="fas fa-regular fa-camera"></i>
                    <p className="item_title" style={{}}>
                      {" "}
                      or drag them in
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  multiple={true}
                  id="closet_icon"
                  hidden
                  onChange={(e) => {
                    console.log(URL.createObjectURL(e.target.files[0]));
                    setEImages((prevImg) => [...prevImg, ...e.target.files]);
                  }}
                />
              </div>
            </div>

            <br></br>

            <div className="input_container">
              <label htmlFor="" className="item_title">
                Item Name (details like brand, style, color, size)
              </label>
              <input
                type="text"
                id="itemName"
                name="itemName"
                onChange={formik.handleChange}
                value={formik.values.itemName}
              />
              {formik.touched.itemName && formik.errors.itemName ? (
                <small className="form-text text-danger">
                  {formik.errors.itemName}
                </small>
              ) : null}
            </div>

            <div className="input_container">
              <label htmlFor="" className="item_title">
                Item Description (any other noteworthy details)
              </label>
              <textarea
                type="text"
                id="itemDesc"
                name="itemDesc"
                className="textarea_input"
                onChange={formik.handleChange}
                value={formik.values.itemDesc}
                rows={10}
              ></textarea>
              {formik.touched.itemDesc && formik.errors.itemDesc ? (
                <small className="form-text text-danger">
                  {formik.errors.itemDesc}
                </small>
              ) : null}
            </div>

            <div className="personal_info_container">
              <div className="select_container">
                <label htmlFor="brand" className="item_title">
                  Brand
                </label>
                <Select
                  options={brands}
                  onChange={(selectedOption) => {
                    setSelectedBrand(selectedOption);
                    formik.setFieldValue("brand", selectedOption.value);
                  }}
                  value={selectedBrand}
                  styles={customStyles}
                />
                {/* <select id="brand" name="brand" onChange={formik.handleChange}
                            value={formik.values.brand} multiple={false}>
                                <option selected>-</option>
                                <option>A</option>
                                <option>B</option>
                                <option>C</option>
                                <option>D</option>
                                <option>E</option>
                            </select> */}
                {formik.touched.brand && formik.errors.brand ? (
                  <small className="form-text text-danger">
                    {formik.errors.brand}
                  </small>
                ) : null}
              </div>
              <div className="select_container">
                <p className="newBrand">
                  <a href="javascript:void();" onClick={openModal}>
                    + Add New Brand
                  </a>
                </p>
              </div>
            </div>

            <div className="personal_info_container">
              <div className="select_container">
                <label htmlFor="category" className="item_title">
                  Choose Category
                </label>
                <Select
                  options={category.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  isMulti
                  onChange={(value) => formik.setFieldValue("category", value)}
                  value={formik.values.category}
                  styles={customStyles}
                  components={{ MultiValueContainer }}
                  formatGroupLabel={formatGroupLabel}
                />
                {/* <select id="category" name="category" onChange={formik.handleChange}
                            value={formik.values.category} multiple={false}>
                                <option selected>-</option>
                                <option>A</option>
                                <option>B</option>
                                <option>C</option>
                                <option>D</option>
                                <option>E</option>
                            </select> */}
                {formik.touched.category && formik.errors.category ? (
                  <small className="form-text text-danger">
                    {formik.errors.category}
                  </small>
                ) : null}
              </div>
              <div className="select_container">
                <label htmlFor="size" className="item_title">
                  Choose Size
                </label>
                <Select
                  options={sizeOptions}
                  onChange={(value) => {
                    setSelectedSize(value);
                    formik.setFieldValue("size", value.value);
                  }}
                  value={selectedSize}
                  styles={customStyles}
                />
                {/* <select id="size" name="size" onChange={formik.handleChange}
                            value={formik.values.size}>
                                <option selected>-</option>
                                <option>A</option>
                                <option>B</option>
                                <option>C</option>
                                <option>D</option>
                                <option>E</option>
                            </select> */}
                {formik.touched.size && formik.errors.size ? (
                  <small className="form-text text-danger">
                    {formik.errors.size}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="personal_info_container">
              <div className="input_container">
                <label htmlFor="itemPrice" className="item_title">
                  Estimated Original Price
                </label>
                <input
                  type="tel"
                  id="itemPrice"
                  name="itemPrice"
                  onChange={formik.handleChange}
                  value={formik.values.itemPrice}
                />
                {formik.touched.itemPrice && formik.errors.itemPrice ? (
                  <small className="form-text text-danger">
                    {formik.errors.itemPrice}
                  </small>
                ) : null}
              </div>
              <div className="select_container">
                <label htmlFor="condition" className="item_title">
                  Current Condition
                </label>
                <Select
                  options={options.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  onChange={(value) => {
                    setSelectedCondition(value);
                    formik.setFieldValue("condition", value.value);
                  }}
                  value={selectedCondition}
                  styles={customStyles}
                />
                {/* <select id="condition" name="condition" onChange={formik.handleChange}
                            value={formik.values.condition}>
                                <option selected>-</option>
                                <option>A</option>
                                <option>B</option>
                                <option>C</option>
                                <option>D</option>
                                <option>E</option>
                            </select> */}
                {formik.touched.condition && formik.errors.condition ? (
                  <small className="form-text text-danger">
                    {formik.errors.condition}
                  </small>
                ) : null}
              </div>
            </div>

            <div
              className="personal_info_container gridContainerContainer"
              style={{
                fontFamily: "LATO !important",
                color: "var(--background-color)",
              }}
            >
              <div className="input_container">
                <label htmlFor="" className="item_title">
                  Select Color
                </label>
              </div>

              <div class="gridContainer">
                <section
                  className={`${
                    colors.includes("black") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("black")}
                >
                  Black
                </section>
                <section
                  className={`${
                    colors.includes("white") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("white")}
                >
                  White
                </section>
                <section
                  className={`${
                    colors.includes("red") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("red")}
                >
                  Red
                </section>
                <section
                  className={`${
                    colors.includes("pink") ? `color_select` : ``
                  } color_button`}
                  onClick={() => {
                    handleColor("pink");
                  }}
                >
                  Pink
                </section>
                <section
                  className={`${
                    colors.includes("orange") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("orange")}
                >
                  Orange
                </section>
                <section
                  className={`${
                    colors.includes("blue") ? `color_select` : ``
                  } color_button`}
                  onClick={() => {
                    handleColor("blue");
                  }}
                >
                  Blue
                </section>
                <section
                  className={`${
                    colors.includes("gold") ? `color_select` : ``
                  } color_button`}
                  onClick={() => {
                    handleColor("gold");
                  }}
                >
                  Gold
                </section>
                <section
                  className={`${
                    colors.includes("navy") ? `color_select` : ``
                  } color_button`}
                  onClick={() => {
                    handleColor("navy");
                  }}
                >
                  Navy
                </section>
                <section
                  className={`${
                    colors.includes("brown") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("brown")}
                >
                  Brown
                </section>
                <section
                  className={`${
                    colors.includes("purple") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("purple")}
                >
                  Purple
                </section>
                <section
                  className={`${
                    colors.includes("grey") ? `color_select` : ``
                  } color_button`}
                  onClick={() => {
                    handleColor("grey");
                  }}
                >
                  Grey
                </section>
                <section
                  className={`${
                    colors.includes("yellow") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("yellow")}
                >
                  Yellow
                </section>
                <section
                  className={`${
                    colors.includes("green") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("green")}
                >
                  Green
                </section>
                <section
                  className={`${
                    colors.includes("tan") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("tan")}
                >
                  Tan
                </section>
                <section
                  className={`${
                    colors.includes("teal") ? `color_select` : ``
                  } color_button`}
                  onClick={() => {
                    handleColor("teal");
                  }}
                >
                  Teal
                </section>
                <section
                  className={`${
                    colors.includes("cream") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("cream")}
                >
                  Cream
                </section>
                <section
                  className={`${
                    colors.includes("silver") ? `color_select` : ``
                  } color_button`}
                  onClick={() => handleColor("silver")}
                >
                  Silver
                </section>
              </div>
              {/* <div
                className="h6"
                style={{ marginTop: "1rem", fontFamily: "LATO" }}
              >
                <span>Specific Color : </span>
                <input
                  type="text"
                  style={{
                    padding: ".5rem",
                    margin: "0rem 1rem",
                    border: "none",
                    borderBottom: "2px solid var(--background-color)",
                  }}
                  placeholder="Enter Desired Color"
                  name="personalColor"
                  id="personalColor"
                  onChange={formik.handleChange}
                  value={formik.values.personalColor}
                />
                <span>(Optional)</span>
              </div> */}
              {formik.touched.color && formik.errors.color ? (
                <small className="form-text text-danger">
                  {formik.errors.color}
                </small>
              ) : null}
            </div>

            <div style={{ paddingLeft: "10px", paddingBottom: "20px" }}>
              <input
                type="checkbox"
                className="tc_input"
                checked={visibleChecked}
                onChange={() => {
                  if(visibleChecked){
                    formik.setFieldValue("quantity", 1)
                  }
                  visibleSetChecked(!visibleChecked)
                }}
              />
              <label
                style={{ color: "#506385", font: "25px", paddingLeft: "10px" }}
              >
                <b>Do you have more than 1 of this item to share?</b>
              </label>
            </div>

            {visibleChecked && (
              <div className="input_container">
                <input
                  type="text"
                  id="quantity"
                  name="quantity"
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                />
                {formik.touched.quantity && formik.errors.quantity ? (
                  <small className="form-text text-danger">
                    {formik.errors.quantity}
                  </small>
                ) : null}
                <br></br>
                <br></br>
              </div>
            )}

            <div style={{ paddingLeft: "10px", paddingBottom: "20px" }}>
              <input
                type="checkbox"
                className="tc_input"
                onChange={(e) => {
                  e.target.checked
                    ? formik.setFieldValue("shipping", "false")
                    : formik.setFieldValue("shipping", "true");
                }}
              />
              <label
                style={{ color: "#506385", font: "25px", paddingLeft: "10px" }}
              >
                <b>Mark this product as local pickup only (too big to ship).</b>
              </label>
            </div>

            <button
              className="btn dark-btn"
              type="submit"
              disabled={itemStatus === "loading" ? true : false}
            >
              {itemStatus === "loading" ? "Please Wait" : "Update ITEM"}
            </button>

            {/* <button className='btn dark-btn' type="submit" >Update Item</button> */}

            <div className="personal_info_container">
              <div className="select_container">
                <p className="newBrand saveDraft">
                  <a href="javascript:void();">or Save Draft</a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={groupLabelStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const MultiValueContainer = (value) => {
  let ct =
    value.data && value.data["value"].slice(value.data["value"].length - 1);
  return (
    value.data && (
      <div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
        <div>
          {ct === "M"
            ? "🧔‍♂️"
            : ct === "W"
            ? "👩"
            : ct === "B"
            ? "👦"
            : ct === "G"
            ? "👧"
            : "⭐️"}
        </div>
        <components.MultiValueContainer {...value} />
      </div>
    )
  );
};

export default EditItems;
