import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getUsers,
  updateUser,
  resetUpdateStatus,
  deleteUser,
  unbanUser,
  searchUsers,
  cancelSubscription,
  pauseSubscription,
} from "../../../components/StateSlices/adminSlice";
import Spinner1 from "../../../components/Spinner1/Spinner1";

import "./Users.css";
import Modal from "react-modal";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { FaEdit, FaFileDownload, FaPen, FaUndo } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import {
  MdOutlineCancel,
  MdSubscriptions,
  MdChat,
  MdAttachment,
} from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "../../../components/Pagination/Pagination";
import { getDataExport } from "../../../components/StateSlices/adminDataExportSlice";
import AdminEditProfile from "./AdminEditProfile";

const userFilterOptions = [
  { label: "Active", value: "active" },
  // { label: "Inactive", value: "inactive" },
  { label: "Paid", value: "paid" },
  { label: "Free", value: "free" },
  { label: "Paused", value: "pause" },
  { label: "Banned", value: "ban" },
];

function Users({ tab, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const {
    allUserStatus,
    allUsersInfo,
    allUserError,
    updateUserStatus,
    totalCount,
  } = useSelector((state) => state.admin);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [allUsersInfoState, setAllUsersInfoState] = useState(null);

  useEffect(() => {
    if (allUsersInfo && allUsersInfo.length > 0) {
      setAllUsersInfoState(allUsersInfo);
    }
  }, [allUsersInfo]);

  // useEffect(() => {
  //   let token = localStorage.getItem("clttoken");
  //   if (token) {
  //     dispatch(getUsers({ token }));
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/404", { replace: true });
  //   }
  // }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
      fontSize: "14px",
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      marginTop: "3px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#F26654" : "#fff",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "#F26654"
          : "rgba(242, 102, 84, 0.5)",
        color: "#fff",
      },
    }),
    // placeholder:(provided,state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={groupLabelStyles}>{data.label}</span>
    </div>
  );

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "3px",
    borderBottom: "2px solid #F26654",
  };

  const groupLabelStyles = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#F26654",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [subModalIsOpen, setSubModalIsOpen] = useState(null);
  const [role, setRole] = useState(null);
  const [status, setStatus] = useState(null);
  const [hangerCredits, setHangerCredits] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [currentId, setCurrentId] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [hangerHistory, setHangerHistory] = useState([]);
  const [orinalEmail, setOriginalEmail] = useState(null);
  const [emailEdit, setEmailEdit] = useState(false);
  const [roleEdit, setRoleEdit] = useState(false);
  const [userSearchVal, setUserSearchVal] = useState(null);
  const [pauseMonth, setPauseMonth] = useState({});
  const [reasonChange, setReasonChange] = useState("");
  const [userFilter, setUserFilter] = useState(["active"]);

  const subscriptionPauseOptions = [
    { label: "Pause for 1 Month", value: 1 },
    { label: "Pause for 2 Month", value: 2 },
    { label: "Pause for 3 Month", value: 3 },
  ].sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // console.log(pageNumber);
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ==
          pageNumber
        ) {
          // console.log("THen this");
          dispatch(
            getUsers({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: userSearchVal,
              status: tab,
              filter: userFilter,
              type,
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        // console.log("This");
        dispatch(
          getUsers({
            token,
            p: pageNumber,
            search: userSearchVal,
            status: tab,
            filter: userFilter,
            type,
          })
        );
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab, type, userFilter]);

  const updateDetails = (e) => {
    e.preventDefault();
    if (currentId) {
      let token = localStorage.getItem("clttoken");
      console.log(hangerCredits);
      if (token) {
        if (
          (Number(hangerCredits) != NaN &&
            hangerCredits >= 0 &&
            hangerCredits) ||
          emailEdit ||
          roleEdit
        ) {
          if (reasonChange !== "" || emailEdit || roleEdit) {
            dispatch(
              updateUser({
                token,
                id: currentId,
                payload: {
                  role: role.value,
                  hangerCredits: hangerCredits,
                  reasonChange,
                  mode: status.value === "test" ? "test" : "live",
                  newEmail: currentEmail,
                },
                p: pageNumber,
                search: userSearchVal,
              })
            );
          } else {
            toast.error(
              "Please Enter Reason for Hanger Credits Update",
              toastOption
            );
          }
        } else {
          toast.error("Please Enter Valid Hanger Credits", toastOption);
        }
      } else {
        navigate("/404", { replace: true });
      }
    } else {
      toast.error("Something went wrong!! Please Try Again", toastOption);
      setIsOpen(false);
    }
  };

  const deleteUserWar = (id) => {
    swal({
      title: tab === "ban" ? "Unban User" : "Delete User",
      text:
        tab === "ban"
          ? "Once unbanned, User will be able to log In"
          : "Once Deleted, User will be not able to log In",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          if (tab === "ban") {
            dispatch(
              unbanUser({
                token,
                id,
                p: pageNumber,
                search: userSearchVal,
                status: tab,
              })
            );
          } else {
            dispatch(
              deleteUser({
                token,
                id,
                p: pageNumber,
                search: userSearchVal,
                type,
              })
            );
          }
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const cancelUserSub = (id) => {
    swal({
      title: "Cancel User Subscription",
      text: "Once Deleted, User will be transferred to Guest User",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            cancelSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const pauseUserSub = (id, month) => {
    swal({
      title: "Pause User Subscription",
      text: `You want to Pause User Subscription for ${month} month`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            pauseSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
              month,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  if (updateUserStatus === "succeeded") {
    toast.success("User Details Updated Successfully", toastOption);
    dispatch(resetUpdateStatus());
    setCurrentEmail(null);
    setHangerHistory([]);
    setEmailEdit(false);
    setRoleEdit(false);
    setOriginalEmail(null);
    setCurrentId(null);
    setCurrentUserName(null);
    setHangerCredits(0);
    setRole(null);
    setStatus(null);
    setIsOpen(false);
    setSubModalIsOpen(false);
  }

  if (updateUserStatus === "failed") {
    toast.error(allUserError, toastOption);
    dispatch(resetUpdateStatus());
    setCurrentEmail(null);
    setHangerHistory([]);
    setEmailEdit(false);
    setRoleEdit(false);
    setOriginalEmail(null);
    setCurrentId(null);
    setCurrentUserName(null);
    setHangerCredits(0);
    setRole(null);
    setStatus(null);
    setIsOpen(false);
    setSubModalIsOpen(false);
  }

  const resetSearch = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // setPageNumber(0)
      navigate(`/admin/users?page=1`, { replace: true });
      if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
        dispatch(getUsers({ token, p: 0, status: tab, type }));
      }
      //
    } else {
      navigate("/404", { replace: true });
    }
  };

  const downloadUserCsv = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        getDataExport({
          token,
          model: "userDetails",
          query: tab === "active" ? "active" : "ban",
        })
      );
      toast.info("Please Wait for a While", toastOption);
    }
  };

  const [isEditProfileModal, setIsEditProfileModal] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isEditProfileModal}
        onRequestClose={() => setIsEditProfileModal(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "900px",
            maxHeight: "500px",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Edit User Profile Modal"
      >
        <AdminEditProfile
          type={type}
          userSearchVal={userSearchVal}
          pageNumber={pageNumber}
          tab={tab}
          setIsEditProfileModal={setIsEditProfileModal}
          userId={editUserId}
          setAllUsersInfoState={setAllUsersInfoState}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={subModalIsOpen?.display}
        onRequestClose={() => setSubModalIsOpen({ display: false, user: null })}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "800px",
            maxHeight: "500px",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            zIndex: 1,
            minHeight: "maxcontent",
          }}
        >
          <div
            className="h5 bold-text background-text"
            style={{ marginBottom: "1rem" }}
          >
            Handle Subscription
          </div>
          <div
            className="h6 bold-text grey-text"
            style={{ marginBottom: "1rem" }}
          >
            Pause Subscription
          </div>
          <Select
            id={`subscriptionHandler${subModalIsOpen?.user?._id}`}
            name={`subscriptionHandler${subModalIsOpen?.user?._id}`}
            options={subscriptionPauseOptions}
            onChange={(selectedOption) => {
              setPauseMonth(() => {
                return {
                  [subModalIsOpen?.user?._id]: {
                    label: `Pause for ${selectedOption.value} Month`,
                    value: selectedOption.value,
                  },
                };
              });
              pauseUserSub(subModalIsOpen?.user?._id, selectedOption.value);
            }}
            value={pauseMonth[subModalIsOpen?.user?._id]}
            styles={{
              ...customStyles,
            }}
            placeholder="Subscription Handler"
          ></Select>
          {/* <br />
          <br />
          <br /> */}
          {/* <div className="h5 bold-text background-text">
            Cancel Subscription
          </div> */}
          <div
            className="h6 bold-text grey-text"
            style={{ marginBottom: "1rem", marginTop: "2rem" }}
          >
            Delete Subscription
          </div>
          <button
            title="Cancel Subscription"
            className="btn"
            style={{
              border: "none",
              background: "red",
              color: "#fff",
              margin: "auto",
              marginRight: 0,
            }}
            onClick={() => {
              cancelUserSub(subModalIsOpen?.user?._id);
            }}
          >
            Cancel Membership &nbsp;
            {/* <MdOutlineCancel size={"1.2em"} /> */}
          </button>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "900px",
            maxHeight: "500px",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
            className="btn dark-btn"
            style={{ padding: "4px 9.5px", borderRadius: "2px" }}
            onClick={() => this.setState({ modalIsOpen: false })}
          >
            X
          </button> */}
        </div>

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <form onSubmit={(e) => updateDetails(e)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <h3>User Details Edit</h3>
                  <hr
                    style={{
                      borderStyle: "solid",
                      borderColor: "var(--secondary-color)",
                      width: "50px",
                      borderWidth: "2px",
                      marginBottom: "10px",
                    }}
                  ></hr>
                </div>
                <div>
                  <button
                    className="btn dark-btn user-submit-details-btn"
                    type="submit"
                    style={{
                      borderRadius: "2px",
                      // display: window.innerWidth < 640 && "none",
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    User Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    // onChange={(e) => setBrandName(e.target.value)}
                    value={currentUserName}
                    disabled={true}
                    style={{
                      padding: "9.5px",
                      cursor: "not-allowed",
                      background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    User Email
                  </label>
                  <input
                    type="text"
                    id="userEmail"
                    name="userEmail"
                    onChange={(e) => setCurrentEmail(e.target.value)}
                    value={currentEmail}
                    disabled={!emailEdit ? true : false}
                    style={
                      !emailEdit
                        ? {
                            padding: "9.5px",
                            cursor: "not-allowed",
                            background: "#eee",
                            fontSize: "14px",
                          }
                        : {
                            padding: "9.5px",
                            // cursor: "not-allowed",
                            // background: "#eee",
                            fontSize: "14px",
                          }
                    }
                  />
                </div>
              </div>
              {!emailEdit && (
                <>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      className=""
                      style={{
                        margin: "15px 10px 15px 0px",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1 / 2,
                      }}
                    >
                      <label htmlFor="" className="item_title">
                        Role
                      </label>
                      <Select
                        options={[
                          { label: "admin", value: "admin" },
                          { label: "user", value: "user" },
                          // { label: "test", value: "test" },
                        ]}
                        onChange={(value) => {
                          setRole(value);
                        }}
                        value={role}
                        styles={customStyles}
                        placeholder="Assign Role"
                      ></Select>
                    </div>
                    <div
                      className=""
                      style={{
                        margin: "15px 10px 15px 0px",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1 / 2,
                      }}
                    >
                      <label htmlFor="" className="item_title">
                        Hanger Credits
                      </label>
                      <input
                        type="number"
                        id="userEmail"
                        name="userEmail"
                        // onChange={(e) => setBrandName(e.target.value)}
                        onChange={(e) => setHangerCredits(e.target.value)}
                        value={hangerCredits}
                        placeholder="Update Hanger Credits"
                        style={{
                          padding: "9.5px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="" className="item_title">
                      Status
                    </label>
                    <Select
                      options={[
                        { label: "live", value: "live" },
                        { label: "test", value: "test" },
                      ]}
                      onChange={(value) => {
                        setRoleEdit(true);
                        setStatus(value);
                      }}
                      value={status}
                      styles={customStyles}
                      placeholder="Assign Status"
                    ></Select>
                  </div>

                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      className=""
                      style={{
                        margin: "15px 10px 15px 0px",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <label htmlFor="" className="item_title">
                        Reason for Change
                      </label>
                      <input
                        type="text"
                        id="reasonChange"
                        name="reasonChange"
                        // onChange={(e) => setBrandName(e.target.value)}
                        onChange={(e) => setReasonChange(e.target.value)}
                        value={reasonChange}
                        placeholder="Reason for Hanger Credits Update"
                        style={{
                          padding: "9.5px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div
                className="brand_error"
                style={{ color: "red", marginBottom: "10px" }}
              >
                {/* {brandErrorDisplay} */}
              </div>
            </form>
            {!emailEdit && (
              <div
                style={{
                  margin: "2rem auto",
                  // borderTop: "1px solid var(--background-color)",
                }}
              >
                <h3>Hanger Details</h3>
                <hr
                  style={{
                    borderStyle: "solid",
                    borderColor: "var(--secondary-color)",
                    width: "50px",
                    borderWidth: "2px",
                    marginBottom: "10px",
                  }}
                ></hr>
                <table>
                  <thead>
                    <th
                      style={{
                        fontSize: "16px",
                        color: "var(--background-color)",
                        minWidth: "200px",
                      }}
                    >
                      Event
                    </th>
                    <th
                      style={{
                        fontSize: "16px",
                        color: "var(--background-color)",
                        minWidth: "120px",
                      }}
                    >
                      Hanger
                    </th>
                    <th
                      style={{
                        fontSize: "16px",
                        color: "var(--background-color)",
                        minWidth: "120px",
                      }}
                    >
                      Date
                    </th>
                  </thead>
                  <tbody>
                    {hangerHistory &&
                      hangerHistory.length > 0 &&
                      hangerHistory.map((item, index) => {
                        return (
                          <tr key={index}>
                            {index === 0 && console.log("k2l", item)}
                            <td style={{ fontSize: "14px" }}>
                              {item.hangerEvent}
                            </td>
                            <td style={{ fontSize: "14px" }}>
                              {item.hangerNumber}
                            </td>
                            <td style={{ fontSize: "14px" }}>
                              {(new Date(item.time).getMonth() + 1)
                                .toString()
                                .padStart(2, "0") +
                                "-" +
                                new Date(item.time)
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0") +
                                "-" +
                                new Date(item.time).getFullYear()}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <div className="users-header">
        <div
          className="h2 background-text bold-text heading-title"
          style={{ minWidth: "30%" }}
        >
          Manage Users
        </div>
        <div className="other-users-header-flex">
          <div
            className="input_container search_closet"
            style={{ marginTop: "0", padding: "10px 5px" }}
          >
            <form
              className="input_container_wrapper"
              onSubmit={(e) => {
                e.preventDefault();
                let token = localStorage.getItem("clttoken");
                if (token) {
                  // setPageNumber(0)
                  navigate(`/admin/users?page=1`, { replace: true });
                  if (
                    location.search.slice(-1) &&
                    location.search.slice(location.search.indexOf("=") + 1) -
                      1 ==
                      0
                  ) {
                    dispatch(
                      getUsers({
                        token,
                        p: 0,
                        search: userSearchVal,
                        status: tab,
                        type,
                      })
                    );
                  }
                } else {
                  navigate("/404", { replace: true });
                }
              }}
              style={{ marginRight: "1rem" }}
            >
              <div style={{ display: "flex", flex: 1 }}>
                <input
                  type="text"
                  id="userSearch"
                  name="userSearch"
                  onChange={(e) => setUserSearchVal(e.target.value)}
                  value={userSearchVal}
                  placeholder="Enter User Email"
                  style={{ marginTop: "0px", fontFamily: "inherit" }}
                />
                <button
                  type="submit"
                  style={{ borderRadius: "0 5px 5px 0", padding: "10px 12px" }}
                  className="btn"
                >
                  <i class="fas fa-search fa-lg"></i>
                </button>
              </div>
              <div>
                {userSearchVal && (
                  <span
                    className="small-text"
                    style={{
                      marginRight: 0,
                      // float: "right",
                      cursor: "pointer",
                      color: "var(--background-color)",
                      fontSize: "12px",
                      fontFamily: "LATO",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      resetSearch();
                      setUserSearchVal("");
                    }}
                  >
                    Reset <FaUndo style={{ fontSize: "11px" }} />
                  </span>
                )}
              </div>
            </form>
          </div>
          <button
            style={{
              padding: "6px 8px",
            }}
            className="btn"
            onClick={downloadUserCsv}
            title="Download CSV File of All Users"
          >
            <FaFileDownload size={"1.7em"} />
          </button>
        </div>
      </div>
      {/* <br /> */}
      <div
        style={{
          margin: ".5rem 1.25rem",

          borderTop: "1px solid var(-grey-color)",
        }}
      >
        <Select
          defaultValue={[userFilterOptions[0]]}
          isMulti
          name="userfilteroptions"
          options={userFilterOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Filter Users..."
          formatGroupLabel={formatGroupLabel}
          onChange={(val) => {
            const values = val.map((v) => v.value);
            setPageNumber(0);
            setUserFilter(values);
          }}
          // styles={{
          //   control: (baseStyles, state) => ({
          //     ...baseStyles,
          //     borderColor: state.isFocused ? "grey" : "red",
          //   }),
          // }}
        />
      </div>
      {/* <br /> */}
      <div className="users">
        {tab === "active" && (
          <table className="users-table">
            {allUserStatus === "loading" ? (
              <Spinner1 />
            ) : (
              <>
                {allUsersInfoState && allUsersInfoState.length === 0 ? (
                  <div
                    style={{ marginTop: "1rem", marginBottom: "4rem" }}
                    className="no_item"
                  >
                    <h1 className="h1" style={{ marginTop: "1rem" }}>
                      No User Found
                    </h1>
                  </div>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Closet Name</th>
                        <th>Membership</th>
                        <th style={{ minWidth: "120px" }}>Created At</th>
                        <th>Hanger Credits</th>
                        <th>Eco Cycle Score</th>
                        <th>Manage Subscription</th>
                        <th>User Deletion</th>
                        <th>Chat</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUsersInfoState &&
                        allUsersInfoState.length > 0 &&
                        allUsersInfoState.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="h6"
                                style={{ lineBreak: "anywhere" }}
                              >
                                {user.userFName + " " + user.userLName}
                              </td>
                              <td
                                className="h6"
                                style={{ lineBreak: "anywhere" }}
                              >
                                <span>{user.userEmail}</span>{" "}
                                <button
                                  style={{
                                    background: "transparent",
                                    padding: "3px",
                                    color: "var(--primary-color)",
                                  }}
                                  title="Edit User Role"
                                  onClick={() => {
                                    setCurrentId(user._id);
                                    setRole(
                                      user.role
                                        ? { label: user.role, value: user.role }
                                        : { label: "user", value: "user" }
                                    );
                                    setStatus(
                                      user.mode
                                        ? { label: user.mode, value: user.mode }
                                        : { label: "live", value: "live" }
                                    );
                                    setHangerCredits(user.userHanger);
                                    setCurrentEmail(user.userEmail);
                                    setEmailEdit(true);
                                    setOriginalEmail(user.userEmail);
                                    setCurrentUserName(
                                      user.userFName + " " + user.userLName
                                    );
                                    setIsOpen((prev) => !prev);
                                  }}
                                >
                                  <FaPen size={".8em"} />
                                </button>
                              </td>
                              <td
                                className="h6"
                                style={{ lineBreak: "anywhere" }}
                              >
                                {user.closetName}
                              </td>
                              <td
                                className="h6"
                                style={{ textTransform: "capitalize" }}
                              >
                                {user.membership}
                              </td>
                              <td className="h6">
                                {(new Date(user.createdAt).getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0") +
                                  "-" +
                                  new Date(user.createdAt)
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0") +
                                  "-" +
                                  new Date(user.createdAt).getFullYear()}
                              </td>
                              <td className="h6">
                                <span>{user.userHanger}</span>{" "}
                                <button
                                  style={{
                                    background: "transparent",
                                    padding: "3px",
                                    color: "var(--primary-color)",
                                  }}
                                  title="Edit User Role"
                                  onClick={() => {
                                    setCurrentId(user._id);
                                    setRole(
                                      user.role
                                        ? { label: user.role, value: user.role }
                                        : { label: "user", value: "user" }
                                    );
                                    setStatus(
                                      user.mode
                                        ? { label: user.mode, value: user.mode }
                                        : { label: "live", value: "live" }
                                    );
                                    setHangerCredits(user.userHanger);
                                    setCurrentEmail(user.userEmail);
                                    setHangerHistory(
                                      user.hangerHistory.hangerHistory
                                    );
                                    setEmailEdit(false);

                                    setOriginalEmail(user.userEmail);
                                    setCurrentUserName(
                                      user.userFName + " " + user.userLName
                                    );
                                    setIsOpen((prev) => !prev);
                                  }}
                                >
                                  <FaPen size={".8em"} />
                                </button>
                              </td>
                              <td className="h6">
                                {user.ecoCycleScore ? user.ecoCycleScore : 0}
                              </td>

                              {user.membership === "trial" ||
                              user.membership === "onetimeuser" ? (
                                <td className="h6">
                                  {" "}
                                  <h6>Free</h6>
                                  Complementary Membership
                                </td>
                              ) : (
                                <td>
                                  {/* {console.log(
                                    "check",
                                    user.membership,
                                    user.status,
                                    user.subscriptionStatus == false,
                                    "checkkk",
                                    new Date(user.membershipExpiration) >
                                      new Date(user.createdAt)
                                  )} */}
                                  <h6>
                                    {user.membership === "trial"
                                      ? "Free"
                                      : user?.status === "pause"
                                      ? "Paused"
                                      : user.subscriptionStatus === false &&
                                        new Date(user.membershipExpiration) >
                                          new Date(user.createdAt)
                                      ? "Cancelled"
                                      : "Paying"}
                                  </h6>
                                  <button
                                    style={{
                                      borderRadius: "2px",
                                      background: "transparent",
                                      color: "var(--primary-color)",
                                      padding: "5px",
                                    }}
                                  >
                                    <MdSubscriptions
                                      size={"1.4em"}
                                      onClick={() =>
                                        setSubModalIsOpen({
                                          display: true,
                                          user: user,
                                        })
                                      }
                                    />
                                  </button>
                                  {/* <button
                                    title="Cancel Subscription"
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      color: "red",
                                      margin: "auto",
                                      marginRight: 0,
                                      marginTop: "1rem",
                                    }}
                                    onClick={() => {
                                      cancelUserSub(subModalIsOpen?.user?._id);
                                    }}
                                  >
                                    <MdOutlineCancel size={"2em"} />
                                  </button> */}
                                </td>
                              )}
                              <td className="h6">
                                <button
                                  title="Delete User"
                                  style={{
                                    borderRadius: "2px",
                                    background: "transparent",
                                    color: "var(--primary-color)",
                                    padding: "5px",
                                  }}
                                  onClick={() => {
                                    deleteUserWar(user._id);
                                  }}
                                >
                                  <AiFillDelete size={"1.4em"} />
                                </button>
                              </td>
                              <td className="h6">
                                <Link
                                  to={`/chat-support`}
                                  state={{
                                    sellerEmail: user.userEmail,
                                  }}
                                  style={{
                                    borderRadius: "2px",
                                    background: "transparent",
                                    color: "var(--primary-color)",
                                    padding: "5px",
                                  }}
                                >
                                  <MdChat size={"1.4em"} />
                                </Link>
                              </td>
                              <td className="h6">
                                <FaEdit
                                  onClick={() => {
                                    console.log(user);
                                    setIsEditProfileModal(true);
                                    setEditUserId(user._id);
                                  }}
                                  cursor={"pointer"}
                                  color="var(--primary-color)"
                                  size={"1.4em"}
                                />
                              </td>
                              {/* </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}
              </>
            )}
          </table>
        )}
        {tab === "ban" && (
          <table className="users-table">
            {allUserStatus === "loading" ? (
              <Spinner1 />
            ) : (
              <>
                {allUsersInfoState && allUsersInfoState.length === 0 ? (
                  <div
                    style={{ marginTop: "1rem", marginBottom: "4rem" }}
                    className="no_item"
                  >
                    <h1 className="h1" style={{ marginTop: "1rem" }}>
                      No User Found
                    </h1>
                  </div>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Membership</th>
                        <th>Created At</th>
                        <th style={{ minWidth: "180px" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUsersInfoState &&
                        allUsersInfoState.length > 0 &&
                        allUsersInfoState.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td className="h6">
                                {user.userFName + " " + user.userLName}
                              </td>
                              <td className="h6">{user.userEmail}</td>
                              <td
                                className="h6"
                                style={{ textTransform: "capitalize" }}
                              >
                                {user.membership}
                              </td>
                              <td className="h6">
                                {new Date(user.createdAt).getDate() +
                                  "-" +
                                  new Date(user.createdAt).getMonth() +
                                  "-" +
                                  new Date(user.createdAt).getFullYear()}
                              </td>
                              <td className="h6">
                                {/* <button
                                  onClick={() => {
                                    setCurrentId(user._id);
                                    setRole(
                                      user.role
                                        ? { label: user.role, value: user.role }
                                        : { label: "user", value: "user" }
                                    );
                                    setHangerCredits(user.userHanger);
                                    setCurrentEmail(user.userEmail);
                                    setCurrentUserName(
                                      user.userFName + " " + user.userLName
                                    );
                                    setIsOpen((prev) => !prev);
                                  }}
                                >
                                  Unban
                                </button> */}
                                <button
                                  onClick={() => {
                                    deleteUserWar(user._id);
                                  }}
                                >
                                  Unban
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}
              </>
            )}
          </table>
        )}
        {tab === "pause" && (
          <table className="users-table">
            {allUserStatus === "loading" ? (
              <Spinner1 />
            ) : (
              <>
                {allUsersInfoState && allUsersInfoState.length === 0 ? (
                  <div
                    style={{ marginTop: "1rem", marginBottom: "4rem" }}
                    className="no_item"
                  >
                    <h1 className="h1" style={{ marginTop: "1rem" }}>
                      No User Found
                    </h1>
                  </div>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Closet Name</th>
                        <th>Membership</th>
                        <th style={{ minWidth: "120px" }}>Created At</th>
                        <th>Hanger Credits</th>
                        <th>Eco Cycle Score</th>
                        <th>Manage Subscription</th>
                        <th>User Deletion</th>
                        <th>Chat</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUsersInfoState &&
                        allUsersInfoState.length > 0 &&
                        allUsersInfoState.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="h6"
                                style={{ lineBreak: "anywhere" }}
                              >
                                {user.userFName + " " + user.userLName}
                              </td>
                              <td
                                className="h6"
                                style={{ lineBreak: "anywhere" }}
                              >
                                <span>{user.userEmail}</span>{" "}
                                <button
                                  style={{
                                    background: "transparent",
                                    padding: "3px",
                                    color: "var(--primary-color)",
                                  }}
                                  title="Edit User Role"
                                  onClick={() => {
                                    setCurrentId(user._id);
                                    setRole(
                                      user.role
                                        ? { label: user.role, value: user.role }
                                        : { label: "user", value: "user" }
                                    );
                                    setStatus(
                                      user.mode
                                        ? { label: user.mode, value: user.mode }
                                        : { label: "live", value: "live" }
                                    );
                                    setHangerCredits(user.userHanger);
                                    setCurrentEmail(user.userEmail);
                                    setEmailEdit(true);
                                    setOriginalEmail(user.userEmail);
                                    setCurrentUserName(
                                      user.userFName + " " + user.userLName
                                    );
                                    setIsOpen((prev) => !prev);
                                  }}
                                >
                                  <FaPen size={".8em"} />
                                </button>
                              </td>
                              <td
                                className="h6"
                                style={{ lineBreak: "anywhere" }}
                              >
                                {user.closetName}
                              </td>
                              <td
                                className="h6"
                                style={{ textTransform: "capitalize" }}
                              >
                                {user.membership}
                              </td>
                              <td className="h6">
                                {(new Date(user.createdAt).getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0") +
                                  "-" +
                                  new Date(user.createdAt)
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0") +
                                  "-" +
                                  new Date(user.createdAt).getFullYear()}
                              </td>
                              <td className="h6">
                                <span>{user.userHanger}</span>{" "}
                                <button
                                  style={{
                                    background: "transparent",
                                    padding: "3px",
                                    color: "var(--primary-color)",
                                  }}
                                  title="Edit User Role"
                                  onClick={() => {
                                    setCurrentId(user._id);
                                    setRole(
                                      user.role
                                        ? { label: user.role, value: user.role }
                                        : { label: "user", value: "user" }
                                    );
                                    setStatus(
                                      user.mode
                                        ? { label: user.mode, value: user.mode }
                                        : { label: "live", value: "live" }
                                    );
                                    setHangerCredits(user.userHanger);
                                    setCurrentEmail(user.userEmail);
                                    setHangerHistory(
                                      user.hangerHistory.hangerHistory
                                    );
                                    setEmailEdit(false);

                                    setOriginalEmail(user.userEmail);
                                    setCurrentUserName(
                                      user.userFName + " " + user.userLName
                                    );
                                    setIsOpen((prev) => !prev);
                                  }}
                                >
                                  <FaPen size={".8em"} />
                                </button>
                              </td>
                              <td className="h6">
                                {user.ecoCycleScore ? user.ecoCycleScore : 0}
                              </td>

                              {user.membership === "trial" ||
                              user.membership === "onetimeuser" ? (
                                <td className="h6">Complementary Membership</td>
                              ) : (
                                <td>
                                  <h6>Paying</h6>
                                  <button
                                    style={{
                                      borderRadius: "2px",
                                      background: "transparent",
                                      color: "var(--primary-color)",
                                      padding: "5px",
                                    }}
                                  >
                                    <MdSubscriptions
                                      size={"1.4em"}
                                      onClick={() =>
                                        setSubModalIsOpen({
                                          display: true,
                                          user: user,
                                        })
                                      }
                                    />
                                  </button>
                                  {/* <button
                                    title="Cancel Subscription"
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      color: "red",
                                      margin: "auto",
                                      marginRight: 0,
                                      marginTop: "1rem",
                                    }}
                                    onClick={() => {
                                      cancelUserSub(subModalIsOpen?.user?._id);
                                    }}
                                  >
                                    <MdOutlineCancel size={"2em"} />
                                  </button> */}
                                </td>
                              )}
                              <td className="h6">
                                <button
                                  title="Delete User"
                                  style={{
                                    borderRadius: "2px",
                                    background: "transparent",
                                    color: "var(--primary-color)",
                                    padding: "5px",
                                  }}
                                  onClick={() => {
                                    deleteUserWar(user._id);
                                  }}
                                >
                                  <AiFillDelete size={"1.4em"} />
                                </button>
                              </td>
                              <td className="h6">
                                <Link
                                  to={`/chat-support`}
                                  state={{
                                    sellerEmail: user.userEmail,
                                  }}
                                  style={{
                                    borderRadius: "2px",
                                    background: "transparent",
                                    color: "var(--primary-color)",
                                    padding: "5px",
                                  }}
                                >
                                  <MdChat size={"1.4em"} />
                                </Link>
                              </td>
                              <td className="h6">
                                <FaEdit
                                  onClick={() => {
                                    console.log(user);
                                    setIsEditProfileModal(true);
                                    setEditUserId(user._id);
                                  }}
                                  cursor={"pointer"}
                                  color="var(--primary-color)"
                                  size={"1.4em"}
                                />
                              </td>
                              {/* </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}
              </>
            )}
          </table>
        )}
        {totalCount > 20 && (
          <Pagination
            pageCount={Math.ceil(totalCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </>
  );
}

export default Users;
